import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import StackTrace from 'stacktrace-js';
import { AuthService } from '../../authentication/auth.service';
import { EmpleadoService } from '../../services/empleado.service';
import { LogsService } from '../../services/logs.service';
import { RutasService } from '../../services/rutas.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

  public spinner = false;
  error: boolean;

  constructor(
    private _authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _empleadoService: EmpleadoService,
    private _rutasService: RutasService,
    private logger: LogsService
  ) {}

  /**
   * Método que maneja el error sucedido en alguna operación
   * @param error error ocurrido al hacer una operación
   * @todo manejar el error de forma adecuada
   */
  ManejarError(error: any): void {
    StackTrace.fromError(error)
      .then((stackFrame) => this.logger.StackLog(stackFrame))
      .catch((stackError) => this.logger.ErrorLog(error));
    this.spinner = false;
  }

  /**
   * Metodo que se ejecuta al inciar el componente
   */
  async ngOnInit() {

    // Revisar si hay algún error
    if (this.route.snapshot.fragment.includes('error')) {
      this.error = true;
      return;
    }

    // Activar spinner mientras todo carga
    this.spinner = true;
    // Una vez autenticado, se actualiza el header de forma asíncrona
    await this._authService.CompletarProcesoDeAutenticacion().catch(
      (e: any) => throwError(this.ManejarError(e))
    );

    // Además, obtener los datos del empleado
    this._empleadoService
      .ConsultarDatosDelEmpleadoDesdeApi()
      .then(empleado => {
          // El metodo recibe los datos del empleado del API y los datos del usuario del IDS
          this._empleadoService.SetDatosDelEmpleado(empleado, this._authService.ObtenerObjetoUsuario().profile);
          console.log('EL CLIENTE MANEJADO TIENE LOS SIGUIENTE DATOS: ', this._empleadoService.ObtenerObjetoEmpleado());
        }
      ).then(() => {
          this._rutasService .ObtenerPermisosDeRutas(this._empleadoService.ObtenerObjetoEmpleado().Culture)
          .then(permisosRutas => {
            this._rutasService.GuardarPermisosDeRutas(permisosRutas);
            console.log('RUTAS:', this._rutasService.PermisosDeRuta);
          });
        }
      ).finally(() => {
        this.spinner = false;
        this.router.navigate(['/consultas']);
      })
      .catch((e: any) => throwError(this.ManejarError(e)));

    console.log('EL ACCESS TOKEN ES:', this._authService.authorizationHeaderValue);

  }
}
