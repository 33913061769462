import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRuta } from '../interfaces/IRuta';
import { AppConfigService } from './app-config.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class RutasService {
  private url: string;
  private permisosRuta: IRuta[] = [
  {
      Codigo: 'EvoWave_perfil',
      Orden: 1,
      Titulo: 'Mi perfil',
    }
  ];

  constructor(
    private http: HttpClient,
    private _environment: AppConfigService,
    private _urlService: UrlService  ) {
    this._environment.datosDeConfiguracionCargados$.subscribe(datosCargados => {
      if (datosCargados) {
        const webapi = this._urlService.CheckAndCorrectURL(this._environment.config.WebApiUrl);
        this.url = `${webapi}/api/Config/Panels`;
      }
    });
 }

  /**
   * Hace una consulta hacia la configuración del usuario
   * @param deviceCulture cultura seteada del empleado
   */
  public ObtenerPermisosDeRutas(deviceCulture: string): Promise<IRuta[]> {
    const urlRutas = `${this.url}/${deviceCulture}`;
    return this.http.get<IRuta[]>(urlRutas).toPromise();
  }

  public Permisos(deviceCulture: string): Observable<IRuta[]> {
    const urlRutas = `${this.url}/${deviceCulture}`;
    return this.http.get<IRuta[]>(urlRutas);

  }

  /**
   * Guarda un nuevo arreglo de rutas dados por un parámetro
   * @param permisosRuta el arreglo de rutas que retorna el endpoint
   */
  public GuardarPermisosDeRutas(permisosRuta: IRuta[]): void {
    const acciones: IRuta[] = [];

    permisosRuta.forEach((ruta) => {
      const index = acciones.findIndex(r => r.Codigo === ruta.Codigo);

      if (index > -1) {
        acciones[index].Acciones.push(ruta.ActionName);
      } else {
        ruta.Acciones = ruta.ActionName ? [ruta.ActionName] : null;
        acciones.push(ruta);
      }
    });

    this.permisosRuta = this.permisosRuta.concat(acciones);
  }

  /**
   * Retorna las rutas a las que el usuario tiene permitido entrar
   * @returns arreglo de tipo IRuta
   */
  public get PermisosDeRuta(): IRuta[] {
    return this.permisosRuta;
  }

  /**
   * Valida si el usuario tiene el permiso para entrar a alguna ruta.
   * @param ruta: la ruta de la pantalla a la cual el usuario está accediendo.
   * @returns valor boolean si el usuario tiene el permiso de entrar a la ruta o no.
   */
  public PuedeNavegar(ruta: string): boolean {
    return this.permisosRuta.some(
      r => r.Codigo.slice(8, r.Codigo.length).toLowerCase() === ruta);
  }

  public EsVisible(path: string): boolean {
    if (this.permisosRuta.some(permisoRuta => permisoRuta.Codigo === path)) {
      return true;
    }
    return false;
  }

  /**
   * Retorna una lista de posibles acciones que el usuario puede ejecutar sobre una entidad
   * @returns lista de strings con el nombre de cada accion
   */
  public PermisosDeAccion(controllerName: string): string[] {
    return controllerName ? this.permisosRuta.find((val) => val.ControllerName === controllerName).Acciones : [];
  }
}
