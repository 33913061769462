import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  /**
   * Devuelve el mensaje de error por parte del cliente
   * @param error - Mensaje de error
   */
  ObtenerMensajeDeErrorCliente(error: Error): string {
    return error.message ? error.message : error.toString();

  }
  /**
   * Devuelve el error por parte del servidor
   * @param error - Codigo de error por parte del servidor
   */
  ObtenerMensajeDeErrorServer(error: HttpErrorResponse): string {
    return navigator.onLine ? error.message : 'No hay conexión a Internet';
  }
}
