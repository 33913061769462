<div class="page-header">
    <div class="mas-informacion">
        <app-breadcrumb></app-breadcrumb>

        <div class="button-informacion">
            <app-boton-ayuda *ngIf="esDesktop"></app-boton-ayuda>
        </div>
    </div>
    <h1>{{'perfil.titulo' | translate}}</h1>
</div>

<div class="centered-main-content" *ngIf="datosCargados">

    <!-- TARJETA DEL EMPLEADO -->
    <app-tarjeta-empleado
        [nombreEmpleado]="empleado.Nombre"
        [correoEmpleado]="empleado.Email"
        [imagenEmpleado]="empleado.ImageUrl">
    </app-tarjeta-empleado>

    <br>
    <br>
    <div *ngIf="empleosDelUsuario.length > 0; else SinEmpleos">
        <p>{{'perfil.cuenta' | translate}}:</p>
        <br>
    
        <!-- CARTAS DE LOS EMPLEOS -->
        <div *ngFor="let empleo of empleosDelUsuario">
            <app-tarjeta-empleo
                [empleo]="empleo"
                [icono]="empleoSeleccionado === empleo ? 'estrella-full' : 'estrella'"
                [seleccionado]="empleoSeleccionado === empleo"
                [nombrePuesto]="empleo.Puesto.Nombre"
                [nombreUnidad]="empleo.Unidad.Nombre"
                [nombreCompania]="empleo.Compania.Nombre"
                [nombreCentroTrabajo]="empleo.CentroTrabajo.Nombre"
                (cambioDeEmpleo)="ManejarCambioDeEmpleo(empleo)">
            </app-tarjeta-empleo>
            <br>
        </div>
    </div>

    <ng-template #SinEmpleos>
        <p>{{ 'perfil.sinEmpleos' | translate }}</p>
        <br>
    </ng-template>

    <!-- DATOS DEL EMPLEO -->
    <br>
    
    <button mat-flat-button color="primary" (click)="CerrarSesion()">
        <mat-icon aria-hidden="true" style="font-size: 2em;">highlight_off</mat-icon>
        <a style="padding: 0 0.5em;">{{'sidenav.cerrarSesion' | translate}}</a>
    </button>
    <br>    
</div>
