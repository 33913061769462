import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { EmpleadoService } from '../app/core/services/empleado.service';
import { AuthService } from './core/authentication/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'EvolutionWave';
  private config;

  constructor(
    private translate: TranslateService,
    private _empleadoService: EmpleadoService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    this.translate.setDefaultLang('es');
    this._empleadoService.ObtenerLenguaje().subscribe(lenguaje => {
      this.translate.use(lenguaje);
    });

    // Para uso de iconos
    iconRegistry.addSvgIcon(
      'maletin',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-briefcase.svg'));
    iconRegistry.addSvgIcon(
      'sol',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-sun.svg'));
    iconRegistry.addSvgIcon(
      'inbox',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-inbox.svg'));
    iconRegistry.addSvgIcon(
      'gift',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-gift.svg'));
    iconRegistry.addSvgIcon(
      'reloj',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-clock.svg'));
    iconRegistry.addSvgIcon(
      'maximize',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-maximize.svg'));
    iconRegistry.addSvgIcon(
      'check',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-checkmark-circle.svg'));
    iconRegistry.addSvgIcon(
      'persons',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-person-persones.svg'));
    iconRegistry.addSvgIcon(
      'estrella-full',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/star-full.svg'));
    iconRegistry.addSvgIcon(
      'estrella',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/star.svg'));
    iconRegistry.addSvgIcon(
      'question',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-question-mark-circle.svg'));
    iconRegistry.addSvgIcon(
      'alert',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-alert-circle.svg'));
    iconRegistry.addSvgIcon(
      'calendario',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/calendar.svg'));
    iconRegistry.addSvgIcon(
      'actividad',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/activity.svg'));
    iconRegistry.addSvgIcon(
      'left-arrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-arrow-arrow-2-left.svg'));
    iconRegistry.addSvgIcon(
      'texto',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/text.svg'));
    iconRegistry.addSvgIcon(
      'persona-done',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-person-done.svg'));
    iconRegistry.addSvgIcon(
      'circulo-agregar',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/plus-circle.svg'));
    iconRegistry.addSvgIcon(
      'right-arrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-arrow-arrow-2-right.svg'));
    iconRegistry.addSvgIcon(
      'arrow-ios-left',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-arrow-ios-left.svg'));
    iconRegistry.addSvgIcon(
      'arrow-ios-right',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons-arrow-ios-right.svg'));
    iconRegistry.addSvgIcon(
      'alerta',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/warning.svg'));
    iconRegistry.addSvgIcon(
      'award',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/award.svg'));
    iconRegistry.addSvgIcon(
      'time',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/watch.svg'));
    iconRegistry.addSvgIcon(
      'money',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/price_check.svg'));
    iconRegistry.addSvgIcon(
      'money_off',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/money_off.svg'));
    iconRegistry.addSvgIcon(
      'group',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/groups_white.svg'));
    iconRegistry.addSvgIcon(
      'rule',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/rule.svg'));
    iconRegistry.addSvgIcon(
      'pen',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/pen.svg'));
    iconRegistry.addSvgIcon(
      'approve',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/approve.svg'));
  }

  ngOnInit() { }

}
