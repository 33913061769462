import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: any = {
    IdentityServerUrl: 'IDS',
    WebApiUrl: 'API',
    EvolutionWaveUrl: 'EvoWave',
    OneSignalID: 'id'
  };

  private valueListConfig: any = {};

  private appName = 'EvoWave';
  private datosDeConfiguracionCargadosSubject$ = new BehaviorSubject<boolean>(false);
  public datosDeConfiguracionCargados$ = this.datosDeConfiguracionCargadosSubject$.asObservable();

  constructor(
    // private http: HttpClient,
    private injector: Injector,
  ) { }

  /*
   * @returns la configuración cargada de la aplicación como una promesa
   */
  loadAppConfig() {
    const http = this.injector.get(HttpClient);
    return http.get('./assets/app-config.json')
      .toPromise()
      .then(config => {
        this.appConfig = config;
        this.appName = this.substringAppName(this.appConfig.EvolutionWaveUrl);
      });
  }

  /*
   * @returns la configuración cargada de la aplicación como una promesa
   */
  loadValueListConfig() {
    const http = this.injector.get(HttpClient);
    return http.get('./assets/value-list-config.json')
      .toPromise()
      .then(config => {
        this.valueListConfig = config;
        this.datosDeConfiguracionCargadosSubject$.next(true);
      });
  }

  public get configuracionCargada(): Observable<boolean> {
    return this.datosDeConfiguracionCargados$;
  }

  /*
   * @returns la configuración de la aplicacion en formato json
   */
  public get config() {
    return this.appConfig;
  }

  /*
   * @returns el nombre de la aplicación desde el app-config.json
   */
  public get nombreApp() {
    return this.appName;
  }

  /*
   * @returns el nombre de la aplicación desde el app-config.json
   */
  public get valuelistConfig() {
    return this.valueListConfig;
  }

  /*
   * @returns el nombre de la app dado un url
   */
  private substringAppName(appUrl: string) {
    const index = appUrl.indexOf('/', 8);
    return index !== -1 ? appUrl.substring(index + 1, appUrl.length) : '';
  }
}
