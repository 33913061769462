import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { throwError } from 'rxjs';
import { AuthService } from '../../authentication/auth.service';
import { EmpleadoService } from '../../services/empleado.service';
import { RutasService } from '../../services/rutas.service';
import { DialogoLogoutComponent } from '../dialogo-logout/dialogo-logout.component';

@Component({
  selector: 'app-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.scss']
})
export class HomeBottomNavbarComponent {
  public isMobile: boolean;
  public isDesktop: boolean;
  public isTablet: boolean;

  tieneSuscripcion: boolean;

  constructor(
    public dialog: MatDialog,
    private _authService: AuthService,
    private _empleadoService: EmpleadoService,
    private _deviceService: DeviceDetectorService,
    private _rutasService: RutasService
  ) {
    this.DetectarTipoDeDispositivo();
  }

  /**
   * Método que valida el tipo de dispositivo, usando su respectivo servicio
   */
  public DetectarTipoDeDispositivo() {
    this.isMobile = this._deviceService.isMobile();
    this.isDesktop = this._deviceService.isDesktop();
    this.isTablet = this._deviceService.isTablet();
  }

  /**
   * Manejo de los errores en algun procedimiento
   * @param error error ocurrido
   */
  ManejarError(error: any): any {
    throw new Error(error);
  }

  /**
   * Abre el diálogo de logout con la referencia del componente
   */
  public AbrirDialogoLogout(): void {
    const dialogRef = this.dialog.open(DialogoLogoutComponent, {
      width: 'auto'
    });

    dialogRef.afterClosed().subscribe(opcion => {
      if (opcion === 'salir') {
        this.CerrarSesion();
      }
    });
  }

  /**
   * Cierra la sesión del usuario con ayuda el auth service
   */
  public CerrarSesion(): void {
    this._authService.CerrarSesion()
      .catch(
        (e: any) => throwError(this.ManejarError(e))
      );
  }

  public SeDebeMostrar(path: string): boolean {
    return this._rutasService.EsVisible(path);
  }

}
