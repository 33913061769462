import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IEmpleado } from 'src/app/core/interfaces/IEmpleado';
import { IEmpleo } from 'src/app/core/interfaces/IEmpleo';

@Component({
  selector: 'app-tarjeta-empleo',
  templateUrl: './tarjeta-empleo.component.html',
  styleUrls: ['./tarjeta-empleo.component.scss']
})
export class TarjetaEmpleoComponent implements OnInit {

  @Input() icono: string;
  @Input() nombrePuesto: string;
  @Input() nombreUnidad: string;
  @Input() seleccionado: boolean;
  @Input() nombreCompania: string;
  @Input() nombreCentroTrabajo: string;
  @Input() empleo: IEmpleo | null;

  constructor() { }

  @Output()
  cambioDeEmpleo = new EventEmitter<IEmpleo>();

  /**
   * Metodo que emite un evento cuando se apreta el boton de seleccionar el empleo
   */
  public CambioDeEmpleo() {
    this.cambioDeEmpleo.emit(this.empleo);
  }

  ngOnInit(): void {
  }

}
