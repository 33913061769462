import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { IEmpleado } from 'src/app/core/interfaces/IEmpleado';
import { IEmpleo } from 'src/app/core/interfaces/IEmpleo';
import { EmpleadoService } from 'src/app/core/services/empleado.service';
import { StyleManagerService } from '../services/style-manager.service';

@Component({
  selector: 'app-mi-perfil',
  templateUrl: './mi-perfil.component.html',
  styleUrls: ['./mi-perfil.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MiPerfilComponent implements OnInit, OnDestroy {

  // Verificar si el dispositivo es movil o desktop
  public esDesktop: boolean;

  private suscripcionCambioDeEmpleo: Subscription;
  private suscripcionDatosCargados: Subscription;

  public empleosDelUsuario: IEmpleo[] = [];
  public empleoSeleccionado: IEmpleo;
  public empleado: IEmpleado;
  public datosCargados: boolean;

  currentTheme: CustomTheme = {
    name: 'evolution',
    primary: '#00e676',
    accent: '#FFC107'
  };


  constructor(
    private _authService: AuthService,
    private _deviceService: DeviceDetectorService,
    private _empleadoService: EmpleadoService,
    public styleManager: StyleManagerService,
  ) {
    this.datosCargados = false;
    this.ManejarSuscripciones();
    this.DetectarDispositivo();
  }

  /**
   * Actualiza el servicio del empleado para actualizar el empleo seleccionado
   */
  public ManejarCambioDeEmpleo(nuevoEmpleoSeleccionado): void {
    this.empleoSeleccionado = nuevoEmpleoSeleccionado;
    this._empleadoService.ManejarCambioDeEmpleo(nuevoEmpleoSeleccionado);
  }

  /**
   * Metodo que detecta en que dispositivo esta el usuario, si en móvil o en desktop
   * y cambia las variables dependiendo el valor booleano
   */
  public DetectarDispositivo() {
    this.esDesktop = this._deviceService.isDesktop();
  }

  /**
   * Maneja las suscripciones de la ventana hacia otros servicios
   */
  public ManejarSuscripciones(): void {
    this.suscripcionCambioDeEmpleo = this._empleadoService.cambioDeEmpleo$.subscribe(() => {
        this.empleoSeleccionado = this._empleadoService.empleoSeleccionado;
      }
    );

    // Suscripcion para que los datos del empleado sean mostrados una vez cargados
    this.suscripcionDatosCargados = this._empleadoService.datosDelEmpleadoCargados$.subscribe(
      status => {
        this.datosCargados = status;
        if (status) {
          this.empleoSeleccionado = this._empleadoService.empleoSeleccionado;
          this.empleado = this._empleadoService.ObtenerObjetoEmpleado();
          this.empleosDelUsuario = this._empleadoService.ObtenerArrayDeEmpleos();
        }
      }
    );
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.suscripcionCambioDeEmpleo.unsubscribe();
    this.suscripcionDatosCargados.unsubscribe();
  }

  /**
   * Cierra la sesión activa del usuario con identity service.
   */
  public CerrarSesion() {
    this._authService.CerrarSesion();
  }
}

export interface CustomTheme {
  name: string;
  accent: string;
  primary: string;
  isDark?: boolean;
  isDefault?: boolean;
}
