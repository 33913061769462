import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf  } from '@angular/core';
import { AuthGuard } from './authentication/auth.guard';
import { AuthService } from './authentication/auth.service';
import { CustomDatePipePipe } from './handlers/custom-date-pipe.pipe';
import { NotificacionesService } from './services/notificaciones.service';
import { UrlService } from './services/url.service';

@NgModule({
  declarations: [CustomDatePipePipe],
  imports: [
    CommonModule
  ],

  providers: [
    AuthService,
    AuthGuard,
    NotificacionesService,
    UrlService
  ],
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

}
