import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SessionService {
    public codigoMonedaActual: string;
    private _locale: string;

    constructor(){
    }

    set locale(value: string) {
        this._locale = value;
    }
    get locale(): string {
        return this._locale || 'es-US';
    }

    registrarCultura(codigoCultura: string) {
        if (!codigoCultura) {
            return;
        }
        this.locale = codigoCultura;
        const localeId = codigoCultura;

        this.localeInitializer(localeId);
    }

    private async localeInitializer(localeId: string): Promise<any> {
        localeId = localeId.split('-')[0];
        return import(
        /* webpackInclude: /(en|en-US|es|es-CO|es-SV|es-GT|es-HN|es-PA)\.js$/ */
        /* webpackMode: "lazy-once" */
        /* webpackChunkName: "i18n" */
        `@angular/common/locales/${localeId}`
        ).then(module => registerLocaleData(module.default));
    }

    AsignarCodigoMoneda(moneda: string) {
        this.codigoMonedaActual = moneda;
    }
}
