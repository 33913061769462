<div class="container">
  <app-overlay-spinner [show]="haRegresado || activarSpinner"></app-overlay-spinner>

  <mat-sidenav-container 
    [ngClass]="isDesktop ? 'sidenav-container': ''" id="sidenav-drawer"
    [ngStyle]="{'background-image': isDesktop ? 'url(' + imagesRutas.sidenavcontainer + ')' : 'none'}"
    *ngIf="isDesktop; else MovilFrontend"
  >
    <mat-sidenav #drawer
      mode="side"
      role="navigation"
      [opened]="sidenavActivada && !isMobile && !isTablet"
      disableClose
      autosize
    >
      <div class="posicion-sidenav">
        <div class="sidenav-elements">

          <!-- LISTA DE OPCIONES -->
          <mat-nav-list class="sidenav-list sidenav-order">
            <div>
              <div class="posicion-logo">
                <img [src]="imagesRutas['aseinfo']" class="logo">
              </div>

              <div class="posicion-linea">
                <div class="linea-logo"></div>
              </div>

              <div>
                <!-- CONSULTAS ITEM -->
                <div class="wrapper-selected" *ngIf="SeDebeMostrar('EvoWave_Consultas')">
                  <div class="wrapper-background" *ngIf="consultas.isActive">
                    <img [src]="imagesRutas['panel']" class="Group-2">
                  </div>
                  <div class="wrapper-text">
                    <a
                      mat-list-item
                      mat routerLink='/consultas'
                      routerLinkActive
                      #consultas="routerLinkActive"
                      matRipple
                      [matRippleUnbounded]="true"
                      [matRippleRadius]="20">
                      <mat-icon style="margin: 0 0.5rem;"
                                class="icon-size"
                                [ngClass]="consultas.isActive ? 'svg-moss': 'svg-white'"
                                svgIcon="maximize">
                      </mat-icon>
                      <span class="spantext" [ngClass]="consultas.isActive ? 'text-selected': 'text-non-selected'">
                        {{ 'sidenav.consultas' | translate }}
                      </span>
                    </a>
                  </div>
                </div>

                <!-- SOLICITUDES ITEM -->
                <div class="wrapper-selected" *ngIf="SeDebeMostrar('EvoWave_Solicitudes')">
                  <div class="wrapper-background" *ngIf="solicitudes.isActive">
                    <img [src]="imagesRutas['panel']" class="Group-2">
                  </div>
                  <div class="wrapper-text">
                    <a
                    mat-list-item
                    routerLink='/solicitudes'
                    routerLinkActive
                    #solicitudes="routerLinkActive"
                    matRipple
                    [matRippleUnbounded]="true"
                    [matRippleRadius]="20">
                      <mat-icon style="margin: 0 0.5rem;"
                                class="icon-size"
                                [ngClass]="solicitudes.isActive ? 'svg-moss': 'svg-white'"
                                svgIcon="inbox">
                      </mat-icon>
                      <span class="spantext" [ngClass]="solicitudes.isActive ? 'text-selected': 'text-non-selected'">
                        {{ 'sidenav.solicitudes' | translate }}
                      </span>
                    </a>
                  </div>
                </div>

                <!-- AUTORIZACIONES ITEM -->
                <div class="wrapper-selected" *ngIf="SeDebeMostrar('EvoWave_Autorizaciones')">
                  <div class="wrapper-background" *ngIf="autorizaciones.isActive">
                    <img [src]="imagesRutas['panel']" class="Group-2">
                  </div>
                  <div class="wrapper-text">
                    <a
                    mat-list-item
                    routerLink='/autorizaciones'
                    routerLinkActive
                    #autorizaciones="routerLinkActive"
                    matRipple
                    [matRippleUnbounded]="true"
                    [matRippleRadius]="20">
                      <mat-icon style="margin: 0 0.5rem;"
                                class="icon-size"
                                [ngClass]="autorizaciones.isActive ? 'svg-moss': 'svg-white'"
                                svgIcon="check">
                      </mat-icon>
                      <span class="spantext" [ngClass]="autorizaciones.isActive ? 'text-selected': 'text-non-selected'">
                        {{ 'sidenav.autorizaciones' | translate }}
                      </span>
                    </a>
                  </div>
                </div>

                <!-- ACCIONES ITEM -->
                <div class="wrapper-selected" *ngIf="SeDebeMostrar('EvoWave_Acciones')">
                  <div class="wrapper-background" *ngIf="acciones.isActive">
                    <img [src]="imagesRutas['panel']" class="Group-2">
                  </div>
                  <div class="wrapper-text">
                    <a
                    mat-list-item
                    routerLink='/acciones'
                    routerLinkActive
                    #acciones="routerLinkActive"
                    matRipple
                    [matRippleUnbounded]="true"
                    [matRippleRadius]="20">
                      <mat-icon style="margin: 0 0.5rem;"
                                class="icon-size"
                                [ngClass]="acciones.isActive ? 'svg-moss': 'svg-white'"
                                
                      >
                      supervisor_account
                      </mat-icon>
                      <span class="spantext" [ngClass]="acciones.isActive ? 'text-selected': 'text-non-selected'">
                        {{ 'sidenav.acciones' | translate }}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <!-- Perfil -->
              <div
                class="perfil-background Group-2"
                routerLink='/perfil'
                matRipple
                [matRippleUnbounded]="true"
                [matRippleRadius]="20">
                <div class="wrapper-profile">
                  <img *ngIf="estaAutenticado" [src]="urlDeImagen" (error)="ErrorAlCargarImagenDeUsuario($event)" class="profile-pic">
                  <div class="profile-info">
                    <span class="name">
                      {{nombreDelEmpleado}}
                    </span>
                    <span class="puesto" *ngIf="empleoSeleccionado">
                      Puesto: {{empleoSeleccionado.Puesto.Nombre}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </mat-nav-list>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="posicion-rectangle">
      <div
      [ngClass]="estaAutenticado ? 'marca-agua': 'marca-agua-no-autenticado'"
      [ngStyle]="{
        '-webkit-mask': estaAutenticado ? 'url(' + imagesRutas.margaAgua + ') no-repeat 50% 50%' : 'url(' + imagesRutas.margaAguaGrande + ') no-repeat',
        'mask': estaAutenticado ? 'url(' + imagesRutas.margaAgua + ') no-repeat 50% 50%' : 'url(' + imagesRutas.margaAguaGrande + ') no-repeat'
      }"
      ></div>
      <div class="rectangle" [ngClass]="!estaAutenticado ? 'margin-no-autenticado': ''">
        <div class="rentangle-child wrapper-routeroutlet">
          <div class="content-rectangle">
            <router-outlet></router-outlet>
            <div class="push"></div>
          </div>
          <footer class="footer-copyright">
            <p>{{'copyright' | translate}}</p>
          </footer>
        </div>
      </div>
      </mat-sidenav-content>
    </mat-sidenav-container>

  <ng-template #MovilFrontend>
    <div [ngClass]="!estaAutenticado ? 'marca-agua-no-autenticado' : ''"
    [ngStyle]="{
      '-webkit-mask': !estaAutenticado ? 'url(' + imagesRutas.margaAguaGrande + ') no-repeat' : 'none',
      'mask': !estaAutenticado ? 'url(' + imagesRutas.margaAguaGrande + ') no-repeat' : 'none'
    }"
    ></div>
    <div [ngClass]="!estaAutenticado ? 'login-movil' : 'rectangle-movil'" [ngStyle]="{'background-image': !estaAutenticado ? 'url(' + imagesRutas.fondoLogin + ')' : 'none'}">
      <div *ngIf="(isMobile || isTablet)" class="posicion-header">
        <div *ngIf="estaAutenticado" class="header-movil mat-elevation-z0">
          <img [src]="imagesRutas['wordsWhite']" [ngClass]="isMobile ? 'words-white-movil' : 'words-white-tablet'">
          <button mat-icon-button
                  #botonAyuda
                  (click)="AbrirDialogoAyuda();"
                  style="display: flex; justify-content: center; align-items: center;"
          >
            <mat-icon style="margin: 0 0.5rem;"
                      svgIcon="question"
                      class="svg-white"
                      [ngClass]="isMobile ? 'icon-size-info-movil' : 'icon-size-info-tablet'">
            </mat-icon>
          </button>
        </div>
      </div>
      <div class="rentangle-child wrapper-routeroutlet">
        <div class="routerOutlet">
          <div class="content-rectangle-mobile" >
            <router-outlet></router-outlet>

            <app-boton-back [nivelProfundidad]="nivelProfundidad" *ngIf="!estaEnMenu"></app-boton-back>

            <div class="push-mobile"></div>
            <footer class="footer-copyright-mobile">
              <!-- <p>Copyright ASEINFO, 2020. Todos los derechos reservados.</p> -->
              <p>{{'copyright' | translate}}</p>
            </footer>
          </div>
        </div>
        <div class="bottom-navbar" *ngIf="(isMobile || isTablet) && estaAutenticadoObservable">
          <app-bottom-navbar></app-bottom-navbar>
        </div>
      </div>
    </div>
  </ng-template>
</div>
