import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment } from '../../../environments/environment';
import { IEmpleado } from '../interfaces/IEmpleado';
import { IEmpleo } from '../interfaces/IEmpleo';
import { IInformacionEmpleado } from '../interfaces/IInformacionEmpleado';
import { IInformacionEmpleadoItem } from '../interfaces/IInformacionEmpleoItem';
import { IPadreSubalterno,  ISubalterno } from '../interfaces/ISubalterno';
import { AppConfigService } from './app-config.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoService {

  private url;
  private urlSubalternos;
  private datosDelEmpleadoCargadosSubject$ = new BehaviorSubject<boolean>(false);
  private cambioDeEmpleoSubject$ = new BehaviorSubject<boolean>(false);
  private cambioDeLenguaje: BehaviorSubject<string>;
  private empleado: IEmpleado;

  public datosDelEmpleadoCargados$ = this.datosDelEmpleadoCargadosSubject$.asObservable();
  public cambioDeEmpleo$ = this.cambioDeEmpleoSubject$.asObservable();
  public empleoSeleccionado: IEmpleo;
  public datosSubalternos: ISubalterno[];

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
    private _environment: AppConfigService,
    private _urlService: UrlService
  ) {
    this._environment.datosDeConfiguracionCargados$.subscribe(datosCargados => {
      if (datosCargados) {
        const webapi = this._urlService.CheckAndCorrectURL(this._environment.config.WebApiUrl);
        this.url = `${webapi}/api/User/Me`;
        this.urlSubalternos = `${webapi}/api/User/Subalternos`;
      }
    });
    this.cambioDeLenguaje = new BehaviorSubject<string>('es');
  }

  /**
   * Ejecuta un GET del api consultando los datos del empleado
   * @returns una promesa tipo IEmpleado
   */
  public ConsultarDatosDelEmpleadoDesdeApi(): Promise<IEmpleado> {
    return this.http.get<IEmpleado>(this.url).toPromise();
  }

  /**
   * @returns el array de empleos que posee un empleado
   */
  public ObtenerArrayDeEmpleos(): IEmpleo[] {
    return this.empleado.Empleos;
  }

  /**
   * Procesa todos los puestos que posee un empleado, de todos sus empleos
   * @returns array de puestos del empleado
   */
  public ObtenerPuestosDelEmpleado(): object[] {
    const arr: object[] = [];

    this.empleado.Empleos.forEach(empleo => {
      arr.push(empleo.Puesto);
    });
    return arr;
  }

  /**
   * Obtiene los datos de los subalternos del empleado
   * y los guarda en el array de datosSubalternos
   */
  public SetDatosSubalternos(): Promise<IPadreSubalterno> {
    return this.http.get<IPadreSubalterno>(this.urlSubalternos).toPromise();
    // .then(
    //   res => {
    //     console.log('Esto trae la consulta', res);
    //     this.datosSubalternos = res.subalternos;
    //   }
    // );
  }

  /**
   * @returns array de subalternos
   */
  public ObtenerSubalternos(): ISubalterno[] {
    return this.datosSubalternos;
  }

  /**
   * @returns string del nombre del empleado
   */
  public ObtenerNombreEmpleado(): string {
    return this.empleado.Nombre;
  }

  /**
   * @returns string del código de expediente del empleado
   */
  public ObtenerCodigoExpediente(): string {
    if (this.empleado) {
      return this.empleado.CodigoExpediente.toString(10);
    }
    return null;
  }

  /**
   * @returns el objeto tipo empleado
   */
  public ObtenerObjetoEmpleado(): IEmpleado {
    return this.empleado;
  }

  /**
   * @returns valor booleano para saber si ya el usuario tiene los datos cargados
   */
  public DatosCompletosCargados(): boolean {
    if (this.empleado) {
      return this.empleado.Valido;
    }
    return false;
  }

  /**
   * @returns el primer código del empleo en el array de empleos
   */
  public ObtenerPrimerCodigoEmpleo(): number {
    if (this.empleado) {
      if (this.empleado.Empleos.length > 0) {
        return this.empleado.Empleos[0].Codigo;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  /**
   * Obtiene la informacion sobre la planilla del empleado
   */
  public ObtenerInformacionPlanilla(): object[]{
    const arr: object[] = [];
    let codTipoPlanilla;
    let codUnidad;
    let codCentroTrabajo;
    this.empleado.Empleos.map(empleo => {
      if (empleo.Codigo === this.empleoSeleccionado.Codigo){
        codTipoPlanilla = empleo.TipoPlanilla.Codigo;
        codUnidad = empleo.Unidad.Codigo;
        codCentroTrabajo = empleo.CentroTrabajo.Codigo;
        arr.push(codTipoPlanilla);
        arr.push(codUnidad);
        arr.push(codCentroTrabajo);
      }
    });
    return arr;
  }

  /**
   * Asignar valor de lenguaje obtenido de Evolution
   * @param len json de lenguaje a utilizar en el app
   */
  public GuardarLenguaje(len: string): void {
    this.cambioDeLenguaje.next(len);
  }
  /**
   * @returns Observable de lenguaje de Evolution
   */
  public ObtenerLenguaje(): Observable<string> {
    return this.cambioDeLenguaje.asObservable();
  }

  /**
   * Crea el array de empleos del empleado y guarda información del empleado propio
   * como su nombre, user, codigo alternativo, etc.
   * @param empleado objeto con propiedades de la interfaz Empleado
   * @param usuarioIdentityService objeto tipo User propio del IDS
   */
  public SetDatosDelEmpleado(empleado, usuarioIdentityService): void {
    const arrEmpleos: Array<IEmpleo> = new Array<IEmpleo>();

    this.empleado = {
      Nombre: empleado.Nombre,
      Username: empleado.Username,
      CodigoUsuario: empleado.CodigoUsuario,
      CodigoExpediente: usuarioIdentityService.codigo_expediente,
      CodigoAlternativo: empleado.CodigoAlternativo,
      Email: empleado.Email,
      Empleos: [],
      ImageUrl: empleado.ImageUrl,
      esJefe: true,
      Valido: true,
      Culture: usuarioIdentityService.culture,
      Uiculture: usuarioIdentityService.uiculture,
    };

    this.ObtenerEmpleosActivos()
      .then(
        empleosActivos => empleosActivos.forEach(
          empleo => {
            const _emp: IEmpleo = {
              Codigo: empleo.Codigo,
              FechaIngreso: empleo.FechaIngreso,
              CodigoPlaza: empleo.CodigoPlaza,
              Puesto: empleo.Puesto,
              Unidad: empleo.Unidad,
              CentroTrabajo: empleo.CentroTrabajo,
              Compania: empleo.Compania,
              TipoContrato: empleo.TipoContrato,
              TipoPlanilla: empleo.TipoPlanilla,
            };
            arrEmpleos.push(_emp);
          }
        )
      ).then(() => this.empleado.Empleos = arrEmpleos)
      .then(
        () => this.SetDatosSubalternos().then(sub => {
          const informacion: IPadreSubalterno = sub;
          this.datosSubalternos = informacion.subalternos;
          console.log('Aqui estan los subalternos', this.datosSubalternos);
          console.log(this.empleado);
        })
        )
      .then(() => {
        this.empleoSeleccionado = this.empleado.Empleos[0];
        this.datosDelEmpleadoCargadosSubject$.next(this.DatosCompletosCargados());
        this._sessionService.registrarCultura(this.empleado.Culture);
      });


     // NOTE: Esto esta con la condicion de los substrings debido que solamente se tienen 2 lenguajes, 'es' y 'en',
     // entonces se valida que si entra otro tipo de lenguaje, que solo sea español en general,
     // ya en el futuro ya podrá entrar cualquier lenguaje de Evolution
    if (this.empleado.Uiculture !== undefined) {
      const lenguaje = this.empleado.Uiculture.substring(0, 2);
      // this.GuardarLenguaje(this.empleado.Uiculture);
      this.GuardarLenguaje(lenguaje);
    }
  }

  /**
   * Obtiene el codigo del codcia
   * @returns - codcia
   */
  public ObtenerCodciaEmpleoSeleccionado(): number{
    return this.empleoSeleccionado.Compania.Codigo;
  }

  /**
   * Obtiene el código del expediente del empleado autenticado
   * @returns CodigoExpediente
   */
  public get CodigoExpediente(): number {
    return this.empleado.CodigoExpediente;
  }

  /**
   * Busca el nombre de un empleo y devuelve su respectivo código
   * @param nombreEmpleo el nombre completo del empleo
   * @returns -1 si el nombre del empleo no existe, de lo contrario el código del empleo
   */
  public ObtenerCodigoEmpleoSegunNombre(nombreEmpleo: string): number {
    let codigo = -1;

    this.empleado.Empleos.forEach(empleo => {
      if (nombreEmpleo === empleo.Puesto.Nombre) {
        codigo = empleo.Codigo;
      }
    });
    return codigo;
  }


  /**
   * Cambia la variable del servicio a la enviada por parámetro
   * @param empleoSeleccionado Objeto tipo IEmpleo del empleo que se seleccionó
   */
  public ManejarCambioDeEmpleo(empleoSeleccionado: IEmpleo): void {
    // Si el empleo seleccionado es distinto al que ya estaba
    if (this.empleoSeleccionado !== empleoSeleccionado) {
      this.empleoSeleccionado = empleoSeleccionado;
      this.cambioDeEmpleoSubject$.next(true);
    }
  }

  /**
   * Establece los datos inciales de un empleado. El objeto tipo IEmpleado aún no es válido.
   * @param CodigoExpediente el codigo de expediente del empleado
   * @param Username el username de IDS
   * @param Nombre el nombre del empleado acorde a IDS
   * @param Culture culture asignada al usuario
   * @param Uiculture uiculture asignada al usuario
   */
  public SetDatosDeIdentityService(CodigoExpediente, Username, Nombre, Culture, Uiculture): void {
    // Se definen propiedades iniciales del empleado
    this.empleado = {
      Nombre, Username, CodigoExpediente, Culture, Uiculture
    };
  }

  /**
   * @returns codigo del empleo que el usuario ha seleccionado
   */
  public get CodigoEmpleoSeleccionado(): number {
    return this.empleoSeleccionado ? this.empleoSeleccionado.Codigo : 0;
  }

  public get CodigoUsuario(): number {
    return this.empleado.CodigoUsuario;
  }

  public get Usuario(): string {
    return this.empleado.Username;
  }

  public ObtenerCentrodeTrabajoEmpleoSeleccionado(): number{
    return this.empleoSeleccionado.CentroTrabajo.Codigo;
  }

  public ObtenerEmpleosActivos(): Promise<IEmpleo[]> {
    const urlExpedientes = `${this._urlService.CheckAndCorrectURL(this._environment.config.WebApiUrl)}/api/Expedientes/${this.empleado.CodigoAlternativo}/Empleos`;
    return this.http.get<IEmpleo[]>(urlExpedientes).toPromise();
  }

  public ObtenerEmpleo(codigoAlternativo: string): Promise<IEmpleo> {
    const u = `${this._urlService.CheckAndCorrectURL(this._environment.config.WebApiUrl)}/api/Empleos/ObtenerEmpleo/${codigoAlternativo}`;
    return this.http.get<IEmpleo>(u).toPromise();
  }

  public ObtenerInformacionEmpleo(codigoEmpleo: number): Promise<IInformacionEmpleadoItem[]>{
    const url = `${this._urlService.CheckAndCorrectURL(this._environment.config.WebApiUrl)}/api/Empleos/Informacion/${codigoEmpleo}`;
    return this.http.get<IInformacionEmpleadoItem[]>(url).toPromise();
  }
}
