import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import StackTrace from 'stacktrace-js';
import { ErrorService } from '../services/error.service';
import { LogsService } from '../services/logs.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }
    /**
     * Maneja los errores dependiendo si son de server o del cliente
     * @param error - tipo de error
     */
    handleError(error: Error | HttpErrorResponse) {
        const _errorService = this.injector.get(ErrorService);
        const logs = this.injector.get(LogsService);
        let message: string;
        if (error instanceof HttpErrorResponse) {
            message = _errorService.ObtenerMensajeDeErrorServer(error);
            logs.HttpErrorLog(error);
        } else {
            logs.WarnLog(error);
        }

        StackTrace.fromError(error)
          .then((stackFrame) => logs.StackLog(stackFrame))
          .catch((err) => logs.ErrorLog((error as any).Message));
    }
}
