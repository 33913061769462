<mat-toolbar class="toolbar-nav" style="padding: 10px; padding-top: 20px; padding-bottom: 20px; justify-content: space-around">
  <div class="button-navbar"
       routerLink='consultas' 
       routerLinkActive 
       #rla1="routerLinkActive"
       [ngClass]="rla1.isActive ? 'svg-white': 'svg-gray'"
       *ngIf="SeDebeMostrar('EvoWave_Consultas')">
    <button mat-icon-button style="display: flex; justify-content: center; align-items: center;">
      <mat-icon style="margin: 0 0.5rem;"
                svgIcon="maximize"
                [ngClass]="isTablet ? 'icon-size-tablet' : 'icon-size-movil'">
      </mat-icon>
    </button>
    <span class="text-navbar" [ngClass]="isTablet ? 'text-navbar-tablet' : ''">Consultas</span>
  </div>
  <div class="button-navbar"
       routerLink='solicitudes'
       routerLinkActive 
       #rla2="routerLinkActive"
       [ngClass]="rla2.isActive ? 'svg-white': 'svg-gray'"
       *ngIf="SeDebeMostrar('EvoWave_Solicitudes')">
    <button mat-icon-button style="display: flex; justify-content: center; align-items: center;">
      <mat-icon style="margin: 0 0.5rem;"
                svgIcon="inbox"
                [ngClass]="isTablet ? 'icon-size-tablet' : 'icon-size-movil'">
      </mat-icon>
    </button>
    <span class="text-navbar" [ngClass]="isTablet ? 'text-navbar-tablet' : ''">Solicitudes</span>
  </div>
  <div class="button-navbar"
       routerLink='autorizaciones'
       routerLinkActive
       #rla3="routerLinkActive"
       [ngClass]="rla3.isActive ? 'svg-white': 'svg-gray'"
       *ngIf="SeDebeMostrar('EvoWave_Autorizaciones')">
    <button mat-icon-button style="display: flex; justify-content: center; align-items: center;">
      <mat-icon style="margin: 0 0.5rem;"
                svgIcon="check"
                [ngClass]="isTablet ? 'icon-size-tablet' : 'icon-size-movil'">
      </mat-icon>
    </button>
    <span class="text-navbar" [ngClass]="isTablet ? 'text-navbar-tablet' : ''">Autorizaciones</span>
  </div>
  <div class="button-navbar"
       routerLink='acciones'
       routerLinkActive
       #rla4="routerLinkActive"
       [ngClass]="rla4.isActive ? 'svg-white': 'svg-gray'"
       *ngIf="SeDebeMostrar('EvoWave_Acciones')">
    <button mat-icon-button style="display: flex; justify-content: center; align-items: center;">
      <mat-icon style="margin: 0 0.5rem;"
                [ngClass]="isTablet ? 'icon-size-tablet' : 'icon-size-movil'"
      >
        supervisor_account
      </mat-icon>
    </button>
    <span class="text-navbar" [ngClass]="isTablet ? 'text-navbar-tablet' : ''">Acciones</span>
  </div>
  <div class="button-navbar"
       routerLink='perfil'
       routerLinkActive
       #rla5="routerLinkActive"
       [ngClass]="rla5.isActive ? 'svg-white': 'svg-gray'">
    <button mat-icon-button style="display: flex; justify-content: center; align-items: center;">
      <mat-icon style="margin: 0 0.5rem;"
                svgIcon="persons"
                [ngClass]="isTablet ? 'icon-size-tablet' : 'icon-size-movil'">
      </mat-icon>
    </button>
    <span class="text-navbar" [ngClass]="isTablet ? 'text-navbar-tablet' : ''">Perfil</span>
  </div>
</mat-toolbar>







<!-- <igx-bottom-nav> -->

  <!-- CONSULTAS -->
  <!-- <igx-tab label='Consultas' icon='chrome_reader_mode'
    routerLink='consultas' routerLinkActive #rla1="routerLinkActive" [isSelected]="rla1.isActive">
  </igx-tab> -->

  <!-- SOLICITUDES -->
  <!-- <igx-tab label='Solicitudes' icon='ballot'
    routerLink='solicitudes' routerLinkActive #rla2="routerLinkActive" [isSelected]="rla2.isActive">
  </igx-tab> -->

  <!-- AUTORIZACIONES -->
  <!-- <igx-tab label='Autorizaciones' icon='assignment_turned_in'
    routerLink='autorizaciones' routerLinkActive #rla3="routerLinkActive" [isSelected]="rla3.isActive">
  </igx-tab> -->

  <!-- PERFIL -->
  <!-- <igx-tab label='Perfil' icon='person'
    routerLink='perfil' routerLinkActive #rla4="routerLinkActive" [isSelected]="rla4.isActive">
  </igx-tab> -->

  <!-- OPCION PARA VER MÁS OPCIONES -->
  <!-- <igx-tab label='' icon='more_vert' [matMenuTriggerFor]="menu" mat-icon-button
    [isSelected]="rla4.isActive || rla5.isActive">
    <mat-menu #menu="matMenu"> -->

      <!-- SUSCRIBIRSE -->
      <!-- <button *ngIf='!tieneSuscripcion' mat-menu-item (click)='CrearSuscripcion()'>
        <mat-icon>notifications_active</mat-icon>
        <span>Suscribirme</span>
      </button> -->

      <!-- DESSUSCRIBIRSE -->
      <!-- <button *ngIf='tieneSuscripcion' mat-menu-item (click)='CancelarSuscripcion()'>
        <mat-icon>notifications_off</mat-icon>
        <span>Desuscribirme</span>
      </button> -->

      <!-- GESTIONES -->
      <!-- <button mat-menu-item disabled routerlink="/gestiones" routerLinkActive="activo" #rla4="routerLinkActive">
        <mat-icon>source</mat-icon>
        <span>Gestiones</span>
      </button> -->

      <!-- MI PERFIL -->
      <!-- <button mat-menu-item routerLink='/perfil' routerLinkActive="activo" #rla5="routerLinkActive">
        <mat-icon>person</mat-icon>
        <span>Mi Perfil</span>
      </button> -->

      <!-- CERRAR SESIÓN -->
      <!-- <button mat-menu-item (click)="AbrirDialogoLogout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Cerrar Sesión</span>
      </button> -->

    <!-- </mat-menu>
  </igx-tab> -->
<!-- </igx-bottom-nav> -->