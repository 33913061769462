import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/authentication/auth.guard';
import { AuthCallbackComponent } from './core/components/auth-callback/auth-callback.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'consultas',
    loadChildren: () => import('./modules/consultas/consultas.module').then(m => m.ConsultasModule),
    data: { breadcrumb: 'Consultas' },
    canActivate: [AuthGuard]
  },
  {
    path: 'solicitudes',
    loadChildren: () => import('./modules/solicitudes/solicitudes.module').then(m => m.SolicitudesModule),
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Solicitudes' },
  },
  {
    path: 'autorizaciones',
    loadChildren: () => import('./modules/autorizaciones/autorizaciones.module').then(m => m.AutorizacionesModule),
    data: { breadcrumb: 'Autorizaciones' },
    canActivate: [AuthGuard]
  },
  {
    path: 'acciones',
    loadChildren: () => import('./modules/acciones/acciones.module').then(m => m.AccionesModule),
    data: { breadcrumb: 'Acciones' },
    canActivate: [AuthGuard]
  },
  {
    path: 'perfil',
    loadChildren: () => import('./modules/cuenta/cuenta.module').then(m => m.CuentaModule),
    data: { breadcrumb: 'Mi Perfil' }
  },
  { path: '', redirectTo: 'consultas', pathMatch: 'full' , data: {breadcrumb: null} },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  exports: [
    RouterModule
  ],
  imports: [
    RouterModule.forRoot(
      routes,
      // { enableTracing: true } // <- fines de debugging
    )
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
})
export class AppRoutingModule { }
