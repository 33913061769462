<div class="centered-main-content login-content">
    <app-overlay-spinner [show]="spinner"></app-overlay-spinner>
    <mat-card>
        <div style="margin: 3rem 0;">
            <img src="assets/default-image/logo-normal.svg" class="centerd-content">
            <br><br>
            <p>Para ingresar, debe iniciar sesión</p>
            <br><br>
            <button mat-flat-button color="primary" (click)="Login()">
                <a>Ingresar</a>
            </button>
        </div>
    </mat-card>
</div>
