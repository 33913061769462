import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { IBreadCrumb } from './IBreadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: IBreadCrumb[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(() => {
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    });
  }

  /**
   * Acorde a la ruta activada, buildear el breadcrumb recursivamente
   * @param route la ruta activada
   * @param url el URL de navegación completo
   * @param breadcrumbs Objeto tipo IBreadcrumb que contiene etiqueta y URL
   */
  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    // ... implementation of buildBreadCrumbv
    const label =
      route.routeConfig && route.routeConfig.data
        ? route.routeConfig.data.breadcrumb
        : '';
    const path =
      route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    const siguienteURL = path ? `${url}/${path}` : url;

    const breadcrumb: IBreadCrumb = {
      etiqueta: label,
      url: siguienteURL
    };

    // Validamos que la etiqueta no esté en blanco
    const nuevoBreadcrumb = breadcrumb.etiqueta ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
    if (route.firstChild) {
        // Si el URL no coincide con el que estamos actualmente, significa que tenemos que seguir iterando
        // El resto de hijos
        return this.buildBreadCrumb(route.firstChild, siguienteURL, nuevoBreadcrumb);
    }

    return nuevoBreadcrumb;
  }

}
