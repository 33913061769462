<div class="centered-main-content">
  <h2 mat-dialog-title>Cerrar Sesión</h2>
  <div mat-dialog-content>
    <p>¿Realmente quieres salir y cerrar la sesión?</p>
  </div>
  <div mat-dialog-actions style="margin: 0.5rem">
    <button mat-button (click)="ClickEnNo()" cdkFocusInitial>Cancelar</button>
    <button type="button" mat-raised-button color="primary" (click)="ClickEnSalir()">Salir</button>
  </div>
</div>
