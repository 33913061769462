import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, throwError } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LogsService } from 'src/app/core/services/logs.service';
import { UrlService } from 'src/app/core/services/url.service';
import StackTrace from 'stacktrace-js';
import { AuthService } from '../../../core/authentication/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public spinner = false;
  estaAutenticado: boolean;
  subscription: Subscription;

  public logoNormal: string;

  constructor(
    private _authService: AuthService,
    private router: Router,
    private _environment: AppConfigService,
    private _urlService: UrlService,
    private logger: LogsService,
  ) {
    this.logoNormal = `${this._urlService.CheckAndCorrectURL(this._environment.config.EvolutionWaveUrl)}/assets/default-image/logo-normal.svg`;
  }

  ngOnInit(): void {
    this.subscription = this._authService.estaAutenticado$.subscribe(status => this.estaAutenticado = status);
    if (this.estaAutenticado) {
      this.router.navigate(['/consultas']);
    }
  }

  Login() {
    this.spinner = true;
    return this._authService.Login()
      .catch(
        (e: any) => throwError(this.errorHandler(e))
      );
  }

  errorHandler(error: any): void {
    this.spinner = false;
    this.logger.ErrorLog(`Error en login. ${error}`);
  }

  ngOnDestroy() {
    // Anular suscripción para prevenir filtrar memoria
    this.subscription.unsubscribe();
  }

}
