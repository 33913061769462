import { Component, OnInit } from '@angular/core';

import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { DialogoAyudaComponent } from 'src/app/core/components/dialogo-ayuda/dialogo-ayuda.component';
import { HelpService } from 'src/app/core/services/help.service';

@Component({
  selector: 'app-boton-ayuda',
  templateUrl: './boton-ayuda.component.html',
  styleUrls: ['./boton-ayuda.component.scss']
})
export class BotonAyudaComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private _helpService: HelpService,
  ) { }

  /**
   * Abre el diálogo de ayuda con la referencia del componente
   */
  AbrirDialogoAyuda(): void {
    const dialogRef = this.dialog.open(DialogoAyudaComponent, {
      width: '35%',
      data: {
        contenido: this._helpService.ObtenerMensajeDeAyuda()
      }
    });
  }

  /**
   * Permite desenfocar al boton una vez se cierra el sidenav
   * @param boton boton de angular material
   */
  NoEnfocarBoton = (boton: MatButton) => {
    (boton as any)._focusMonitor.stopMonitoring(boton._getHostElement());
  }

  ngOnInit(): void {
  }

}
