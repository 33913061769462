import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MenuService } from 'src/app/core/services/menu.service';

@Component({
  selector: 'app-boton-back',
  templateUrl: './boton-back.component.html',
  styleUrls: ['./boton-back.component.scss']
})
export class BotonBackComponent {

  @Input() nivelProfundidad: number;
  constructor(
    private location: Location,
    private _menuService: MenuService,
  ) { }

  /**
   * Regresa a la ruta previa manejada por el navegador
   */
  RegresarARutaAnterior(): void {
    const regresoAMenu: boolean = this.nivelProfundidad <= 2;
    this._menuService.GuardarHaRegresado(regresoAMenu);
    this.location.back();
  }

}
