import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NGXLogger } from 'ngx-logger';
import { StackFrame } from 'stacktrace-js';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  private deviceInfo: string;
  constructor(
    private logger: NGXLogger,
    private deviceService: DeviceDetectorService
  ) {
    this.deviceInfo = JSON.stringify(this.deviceService.getDeviceInfo());
  }

  /**
   * Se escribe en un log los errores que se generan con el uso de la PWA.
   * @param error - Mensaje de error para escribir en log.
   */
  HttpErrorLog(httpError: HttpErrorResponse) {
    this.logger.error(
      httpError.message,
      httpError.error?.Message,
      `Detail: ${httpError.error?.MessageDetail}`,
      `Error Obj: ${JSON.stringify(httpError)}`,
      `Device: ${this.deviceInfo}`
    );
  }

  ErrorLog(error: any) {
    this.logger.error(
      error.message ?? error,
      `Device: ${this.deviceInfo}`,
    );
  }

  WarnLog(error: Error) {
    this.logger.warn(
      error.message ?? error,
      `Device: ${this.deviceInfo}`
    );
  }


  InfoLog(info: any) {
    const msg = JSON.stringify(info);

    if (msg.includes('svg') || msg.includes('json')) {
      return;
    }

    this.logger.info(
      `CONSULTA: ${msg}`,
      `Device: ${this.deviceInfo}`
    );
  }

  StackLog(stack: StackFrame[]) {
    const stringified = stack.map((sf) => sf.toString()).join('\n');
    this.logger.info(
      stringified,
      `Device: ${this.deviceInfo}`
    );
  }
}
