import { Component, Input, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { PushNotificationService } from 'src/app/core/services/push-notification.service';
import { UrlService } from 'src/app/core/services/url.service';

@Component({
  selector: 'app-tarjeta-empleado',
  templateUrl: './tarjeta-empleado.component.html',
  styleUrls: ['./tarjeta-empleado.component.scss']
})
export class TarjetaEmpleadoComponent {

  @Input() nombreEmpleado: string;
  @Input() correoEmpleado: string;
  @Input() imagenEmpleado: string;

  public suscrito: boolean;
  public isAvailable: boolean;

  constructor(
    private _environment: AppConfigService,
    private _pushNotificationService: PushNotificationService,
    private _urlService: UrlService
  ) {
    this._pushNotificationService.InitOneSignal();
    this._pushNotificationService.ObtenerHasSubscribed().subscribe(val => {
      this.suscrito = val;
    });
    this._pushNotificationService.ObtenerIsAvailable().subscribe(val => {
      this.isAvailable = val;
    });
  }

  /**
   * Cambia la imágen del usuario dado un error al cargarla
   * @param event referencia al evento dentro del html
   */
  ErrorAlCargarImagenDeUsuario(event: any) {
    const imagenDefault = `${this._urlService.CheckAndCorrectURL(this._environment.config.EvolutionWaveUrl)}/assets/default-image/default-profile.png`;
    event.target.src = imagenDefault;
  }

  IniciarSuscripcion() {
    this._pushNotificationService.IniciarSuscripcion();
  }

  CancelarSuscripcion() {
    this._pushNotificationService.MuteNotifications();
  }

}
