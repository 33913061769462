<mat-card>
    <mat-card-content fxLayout="row" fxLayoutAlign="space-around center">
        <div>
            <img [src]="imagenEmpleado" (error)="ErrorAlCargarImagenDeUsuario($event)" class="oval">
        </div>
        <div>
            <p class="texto-nombre">{{nombreEmpleado}}</p>
            <div class="correo" fxLayout="row" fxLayoutAlign="space-around center">
                <p style="color: #a6a6a6">{{'perfil.correo' | translate}}: </p>
                <p>{{correoEmpleado}}</p>
            </div>
            <div class="correo" *ngIf="suscrito || isAvailable; else UndefinedVariable">
                <p *ngIf="!isAvailable" style="color: #a6a6a6; margin-top: 5px;">
                  {{'perfil.notificaciones.navegadorNoSoporta' | translate}}
                </p>
                <p *ngIf="suscrito" style="color: #a6a6a6; margin-top: 5px;">
                  {{'perfil.notificaciones.suscrito' | translate}}
                </p>
                <p *ngIf="!suscrito" style="color: #a6a6a6; margin-top: 5px;">
                  {{'perfil.notificaciones.noSuscrito' | translate}}
                </p>
            </div>
            <ng-template #UndefinedVariable>
                <div class="correo">
                    <p *ngIf="!isAvailable" style="color: #a6a6a6; margin-top: 5px;">
                      {{'perfil.notificaciones.cargandoInformacion' | translate}}
                    </p>
                </div>
            </ng-template>
        </div>
        <div>
            <button *ngIf="!isAvailable" mat-icon-button color="primary" [disabled]="!isAvailable">
                <mat-icon>notifications_off</mat-icon>
            </button>

            <button *ngIf="suscrito && isAvailable" mat-icon-button color="primary" (click)="CancelarSuscripcion()" [disabled]="!isAvailable">
                <mat-icon>notifications_off</mat-icon>
            </button>

            <button *ngIf="!suscrito && isAvailable" mat-icon-button color="primary" (click)="IniciarSuscripcion()" [disabled]="!isAvailable">
                <mat-icon>notifications_active</mat-icon>
            </button>
        </div>
    </mat-card-content>
</mat-card>
