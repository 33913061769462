import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class XFrameInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedReq = request.clone({
      headers: request.headers.set('X-Frame-Options', 'SAMEORIGIN')
        .set('Strict-Transport-Security', 'max-age=31536000 ; includeSubDomains')
    });
    return next.handle(modifiedReq);
  }
}
