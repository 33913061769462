import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-overlay-spinner',
  templateUrl: './overlay-spinner.component.html',
  styleUrls: ['./overlay-spinner.component.scss']
})
export class OverlaySpinnerComponent implements OnInit {

  constructor() { }

  @Input() diameter = 80;
  @Input() mode = 'indeterminate';
  @Input() strokeWidth = 10;
  @Input() color = 'primary';
  @Input() show = false;

  ngOnInit(): void {
  }

}
