import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogo-logout.component',
  templateUrl: 'dialogo-logout.component.html',
  styleUrls: ['dialogo-logout.component.scss']
  })
  export class DialogoLogoutComponent {

  constructor(
      public referenciaDialogo: MatDialogRef<DialogoLogoutComponent>
  ) {}

  /**
   * Maneja el click en el botón "no"
   */
  ClickEnNo(): void {
    this.referenciaDialogo.close();
  }

  /**
   * Maneja el click en el botón "salir"
   */
  ClickEnSalir(): void {
    this.referenciaDialogo.close('salir');
  }
}
