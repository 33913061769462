import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private activar: BehaviorSubject<boolean>;

  constructor() {
    this.activar = new BehaviorSubject<boolean>(false);
  }

  GuardarActivar(activar: boolean): void {
    this.activar.next(activar);
  }

  ObtenerActivar(): Observable<boolean> {
    return this.activar.asObservable();
  }
}
