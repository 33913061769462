import { Location } from '@angular/common';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription, throwError } from 'rxjs';
import { EmpleadoService } from 'src/app/core/services/empleado.service';
import { MenuService } from 'src/app/core/services/menu.service';
import { PushNotificationService } from 'src/app/core/services/push-notification.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { AuthService } from '../../authentication/auth.service';
import { IEmpleo } from '../../interfaces/IEmpleo';
import { AppConfigService } from '../../services/app-config.service';
import { HelpService } from '../../services/help.service';
import { RutasService } from '../../services/rutas.service';
import { UrlService } from '../../services/url.service';
import { DialogoAyudaComponent } from '../dialogo-ayuda/dialogo-ayuda.component';
import { DialogoLogoutComponent } from '../dialogo-logout/dialogo-logout.component';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnDestroy {
  public isMobile: boolean;
  public isDesktop: boolean;
  public isTablet: boolean;
  public sidenavActivada: boolean;
  public urlDeImagen: string;
  public imagenDefault: string;
  public estaAutenticado = false; // Variable donde se debe adquirir si el usaurio esta logueado o no
  public nombreDelEmpleado;
  public puestoDelEmpleado;
  public nombreDePantalla;

  public empleosDelUsuario: IEmpleo[];
  public empleoSeleccionado: IEmpleo;

  public nivelProfundidad = 0;

  estaAutenticadoObservable: boolean;
  haRegresado: boolean;
  estaEnMenu: boolean;
  activarSpinner: boolean;
  datosCargadosObservable: boolean;
  tieneSuscripcion: boolean;

  // Suscripciones
  private suscripcion: Subscription;
  private suscripcionDatosDelEmpleadoCargados: Subscription;
  private suscipcionEmpleoSeleccionado: Subscription;

  ruta: string;
  icono = 'menu';

  public imagesRutas = {
    aseinfo: '',
    panel: '',
    wordsWhite: '',
    fondoLogin: '',
    sidenavcontainer: '',
    margaAgua: '',
    margaAguaGrande: '',
  };

  // Observar botones para evitar que se quede 'activado' una vez se interactúa con otros elementos.
  @ViewChild('botonMenu') botonMenu: MatButton;
  @ViewChild('botonAyuda') botonayuda: MatButton;

  constructor(
    private _authService: AuthService,
    private _deviceService: DeviceDetectorService,
    private _empleadoService: EmpleadoService,
    private _environment: AppConfigService,
    private _helpService: HelpService,
    private _menuService: MenuService,
    private _rutasService: RutasService,
    private _spinnerService: SpinnerService,
    private _urlService: UrlService,
    private _pushNotificationService: PushNotificationService,
    private location: Location,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.imagesRutas.aseinfo = `${this._urlService.CheckAndCorrectURL(
      this._environment.config.EvolutionWaveUrl
    )}/assets/default-image/aseinfo.svg`;
    this.imagesRutas.panel = `${this._urlService.CheckAndCorrectURL(
      this._environment.config.EvolutionWaveUrl
    )}/assets/default-image/panel-group.svg`;
    this.imagesRutas.wordsWhite = `${this._urlService.CheckAndCorrectURL(
      this._environment.config.EvolutionWaveUrl
    )}/assets/default-image/words-white.svg`;
    this.imagesRutas.fondoLogin = `${this._urlService.CheckAndCorrectURL(
      this._environment.config.EvolutionWaveUrl
    )}/assets/default-image/background-gradient.svg`;
    this.imagesRutas.sidenavcontainer = `${this._urlService.CheckAndCorrectURL(
      this._environment.config.EvolutionWaveUrl
    )}/assets/default-image/background-gradient.svg`;
    this.imagesRutas.margaAgua = `${this._urlService.CheckAndCorrectURL(
      this._environment.config.EvolutionWaveUrl
    )}/assets/default-image/marca-agua.svg`;
    this.imagesRutas.margaAguaGrande = `${this._urlService.CheckAndCorrectURL(
      this._environment.config.EvolutionWaveUrl
    )}/assets/default-image/marca-agua-grande.svg`;
    this.imagenDefault = `${this._urlService.CheckAndCorrectURL(
      this._environment.config.EvolutionWaveUrl
    )}/assets/default-image/default-profile.png`;
    this.ManejarSuscripcionesIniciales();

    this.SuscribirseARuta();
    this.DetectarTipoDeDispositivo();
    this._authService.estaAutenticado$.subscribe(
      (value) => {
        this.estaAutenticado = value;
        if (value) {
          this._pushNotificationService.InitOneSignal();
        }
      }
    );
    this._menuService
      .ObtenerHaRegresado()
      .subscribe((value) => (this.haRegresado = value));
    this._menuService
      .ObtenerEstaEnMenu()
      .subscribe((value) => (this.estaEnMenu = value));
    this._spinnerService
      .ObtenerActivar()
      .subscribe((value) => (this.activarSpinner = value));
  }

  /**
   * Método que se suscribe a la ruta de la aplicación y maneja sus cambios
   */
  SuscribirseARuta(): void {
    this.router.events.subscribe((val) => {
      if (this.location.path() !== '') {
        this.ruta = this.location.path();
      } else {
        this.ruta = '';
      }
      this.CambioDeRuta();
    });
  }

  /**
   * Método que valida el tipo de dispositivo, usando su respectivo servicio
   */
  public DetectarTipoDeDispositivo() {
    this.isMobile = this._deviceService.isMobile();
    this.isDesktop = this._deviceService.isDesktop();
    this.isTablet = this._deviceService.isTablet();
  }

  /**
   * Abre el diálogo de ayuda con la referencia del componente
   */
  AbrirDialogoAyuda(): void {
    const dialogRef = this.dialog.open(DialogoAyudaComponent, {
      width: 'auto',
      data: {
        contenido: this._helpService.ObtenerMensajeDeAyuda(),
      },
    });
  }

  /**
   * Cambia la imágen del usuario dado un error al cargarla
   * @param event referencia al evento dentro del html
   */
  ErrorAlCargarImagenDeUsuario(event: any) {
    event.target.src = this.imagenDefault;
  }

  /**
   * Abre el diálogo de logout con la referencia del componente
   */
  AbrirDialogoLogout(): void {
    const dialogRef = this.dialog.open(DialogoLogoutComponent, {
      width: 'auto',
    });

    dialogRef.afterClosed().subscribe((opcion) => {
      if (opcion === 'salir') {
        this.CerrarSesion();
      }
    });
  }

  /**
   * Cierra la sesión del usuario con ayuda el auth service
   */
  CerrarSesion(): void {
    this._authService
      .CerrarSesion()
      .catch((e: any) => throwError(this.ManejarError(e)));
  }

  /**
   * Manejo de los errores en algun procedimiento
   * @param error error ocurrido
   */
  ManejarError(error: any): any {
    throw new Error(error);
  }

  /**
   * Maneja los cambios de ruta, cambiando los nombres de las pantallas y los íconos a utilizar
   */
  CambioDeRuta(): void {
    this.nivelProfundidad = (this.ruta.match(/\//g) || []).length;
    if (
      this.ruta.endsWith('consultas')
        || this.ruta.endsWith('solicitudes')
        || this.ruta.endsWith('autorizaciones')
        || this.ruta.endsWith('acciones')
        || this.ruta.endsWith('perfil')
    ) {
      this.icono = 'menu';
      // Está en alguna de las pestañas principales
      this.nombreDePantalla = 'Evolution PWA';
      this._menuService.GuardarHaRegresado(false);
      this._menuService.GuardarEstaEnMenu(true);

      if (this.datosCargadosObservable && this.estaAutenticadoObservable) {
        this.sidenavActivada = true;
      }
    } else if (this.ruta.endsWith('crear-solicitud')) {
      this._menuService.GuardarHaRegresado(false);
      this.nombreDePantalla = 'Solicitudes';
      this.icono = 'arrow_back';
      this._menuService.GuardarEstaEnMenu(false);
    } else if (
      this.ruta.includes('consulta') &&
      this.ruta.match(new RegExp('/', 'g'))
    ) {
      this.nombreDePantalla = 'Consultas';
      this.icono = 'arrow_back';
      this._menuService.GuardarEstaEnMenu(false);
      this.sidenavActivada = true;
    } else if (
      this.ruta.includes('solicitudes') &&
      this.ruta.match(new RegExp('/', 'g'))
    ) {
      this.nombreDePantalla = 'Solicitudes';
      this.icono = 'arrow_back';
      this._menuService.GuardarEstaEnMenu(false);

    } else if (this.ruta.includes('acciones') && this.ruta.match(new RegExp('/', 'g'))) {
      this.nombreDePantalla = 'Acciones';
      this.icono = 'arrow_back';
      this._menuService.GuardarEstaEnMenu(false);

    } else if (this.ruta.includes('login')) {
      this.icono = '';
      this.sidenavActivada = false;
    } else if (this.ruta.includes('callback')) {
      this.nombreDePantalla = 'Inicio de Sesión';
      this.icono = '';
      this._menuService.GuardarEstaEnMenu(false);
    }
    else {
      this.nombreDePantalla = 'Evolution PWA';
      this.icono = 'arrow_back';
      this._menuService.GuardarEstaEnMenu(true);
    }
  }

  /**
   * Método que, con ayuda del auth service, termina de cargar los datos del empleado y establece variables
   * como el nombreDelEmpleado y el urlDeImagen
   */
  TerminarDeCargarDatosDelUsuario(): void {
    this._authService
      .ObtenerYGuardarDatosDelEmpleado()
      .then(() => {
        // Obtenemos el nombre del empleado
        this.nombreDelEmpleado = this._empleadoService.ObtenerNombreEmpleado();
        // Obtenemos la imagen del empleado
        this.urlDeImagen = `${this._urlService.CheckAndCorrectURL(
          this._environment.config.EvolutionWaveUrl
        )}/assets/default-image/default-profile.png`;
      })
      .finally(() => {
        // Finalmente, activamos sidenav y desactivamos spinner
        this.sidenavActivada = true;
      })
      .catch((error) => {
        this.ManejarError(error);
      });
  }

  /**
   * Metodo que se comunica con el servicio del empleado para manejar un cambio de empleo
   */
  public ManejarCambioDeEmpleo() {
    this._empleadoService.ManejarCambioDeEmpleo(this.empleoSeleccionado);
  }

  /**
   * Metodo que maneja suscripciones a diferentes variables.
   */
  public ManejarSuscripcionesIniciales() {
    //  Suscripción para saber si el usuario está autenticado con IDS o no
    this.suscripcion = this._authService.estaAutenticado$.subscribe(
      (status) => {
        this.estaAutenticadoObservable = status;
        if (
          this.estaAutenticadoObservable &&
          !this._empleadoService.DatosCompletosCargados()
          // FIXME: Ver de que forma se valida que el url no contendo CALLBACK
          // !this.ruta.includes('callback')
        ) {
          this.TerminarDeCargarDatosDelUsuario();
        }
      }
    );

    // Suscripción para saber si los datos el empleado ya fueron cargados por el api
    // NOTE: Esto se implementó ya que el proceso de la primera vez que inicia sesión es un poco distinto a
    // cuando hace refresh a la página.
    this.suscripcionDatosDelEmpleadoCargados = this._empleadoService.datosDelEmpleadoCargados$.subscribe(
      (datosCargados) => {
        this.datosCargadosObservable = datosCargados;
        if (this.datosCargadosObservable && this.estaAutenticadoObservable) {
          this.nombreDelEmpleado = this._empleadoService.ObtenerNombreEmpleado();
          this.empleosDelUsuario = this._empleadoService.ObtenerArrayDeEmpleos();
          this.empleoSeleccionado = this.empleosDelUsuario[0];
          this.urlDeImagen = this._empleadoService.ObtenerObjetoEmpleado().ImageUrl;
        }
      }
    );

    this.suscipcionEmpleoSeleccionado = this._empleadoService.cambioDeEmpleo$.subscribe(
      () => {
        this.empleoSeleccionado = this._empleadoService.empleoSeleccionado;
      }
    );
  }

  ngOnDestroy() {
    // Anular suscripción para prevenir filtrar memoria
    this.suscripcion.unsubscribe();
    this.suscripcionDatosDelEmpleadoCargados.unsubscribe();
  }

  public SeDebeMostrar(path: string): boolean {
    return this._rutasService.EsVisible(path);
  }
}
