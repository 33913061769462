import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor(
    public snackBar: MatSnackBar
  ) { }

  /**
   * Muestra el error interceptado por medio de un componente de Material
   * @param mensaje - Mensaje a mostrar cuando ocurra determinado error
   * @param duracion - Duración (en ms) del snackbar
   */
  MostrarError(mensaje: string, duracion: number = 5000): void {
    this.snackBar.open(mensaje, 'Entendido', { panelClass: ['error', 'justify-text'], duration: duracion });
  }
}
