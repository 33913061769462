<div *ngIf="error" class="centered-main-content">
  <div>
    <div role="alert">
      Ocurrió un error... por favor, intente <a routerLink="/login">hacer login de nuevo</a>.
    </div>
  </div>
</div>

<div *ngIf="!error" class="centered-main-content">
  <h1>Por favor, espere</h1>
  <p>Usted está siendo redireccionado a la página principal...</p>
  <app-overlay-spinner [show]="spinner"></app-overlay-spinner>
</div>