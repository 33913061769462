<div class="centered-main-content">
    <div class="titulo-dialogo">
        <mat-icon style="margin: 0 0.5rem; width: 30px; height: 30px;"
                  svgIcon="question"
                  class="svg-moss">
        </mat-icon>
        <h2 mat-dialog-title style="color: #72a330; font-weight: 500; margin: 0;">{{'help.boton' | translate}}</h2>
    </div>
    <div class="justify-text">
        <div mat-dialog-content>
            <p>{{ data.contenido }}</p>
        </div>
    </div>
    <div mat-dialog-actions style="margin: 0.5rem">
        <button type="button" mat-raised-button color="primary" (click)="ClickEntendido()">{{'help.entendido' | translate | uppercase}}</button>
    </div>
</div>