import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private estaEnMenu: BehaviorSubject<boolean>;
  private haRegresado: BehaviorSubject<boolean>;

  constructor() {
    this.estaEnMenu = new BehaviorSubject<boolean>(true);
    this.haRegresado = new BehaviorSubject<boolean>(false);
  }

  /**
   * Asigna en variable local si se está en el menú o no.
   * @param enMenu - Determina si está o no en el menú.
   */
  GuardarEstaEnMenu(enMenu: boolean): void {
    this.estaEnMenu.next(enMenu);
  }

  /**
   * @returns Si está en menú o no.
   */
  ObtenerEstaEnMenu(): Observable<boolean> {
    return this.estaEnMenu.asObservable();
  }

  /**
   * Asigna en variable local si ha regresado al menú o no.
   * @param haRegresado - Determina si ha regresado al menú o no.
   */
  GuardarHaRegresado(haRegresado: boolean): void {
    this.haRegresado.next(haRegresado);
  }

  /**
   * @returns Si ha regresado al menú o no.
   */
  ObtenerHaRegresado(): Observable<boolean> {
    return this.haRegresado.asObservable();
  }
}
