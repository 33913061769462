// GENERALES
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { MaterialModule } from '../material.module';
import { OverlaySpinnerComponent } from './overlay-spinner/overlay-spinner.component';

// COMPONENTES
import { HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { IgxIconModule, IgxInputGroupModule, IgxTimePickerModule } from 'igniteui-angular';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { BotonAyudaComponent } from './boton-ayuda/boton-ayuda.component';
import { BotonBackComponent } from './boton-back/boton-back.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { ButtonComponent } from './button/button.component';
import { CartasComponent } from './cartas/cartas.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DateComponent } from './date/date.component';
import { DatosUsuarioComponent } from './datos-usuario/datos-usuario.component';
import { DetallesComponent } from './detalles/detalles.component';
import { DialogoRetroalimentacionComponent } from './dialogo-retroalimentacion/dialogo-retroalimentacion.component';
import { DynamicFieldDirective } from './dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { EnConstruccionComponent } from './en-construccion/en-construccion.component';
import { EncabezadoComponent } from './encabezado/encabezado.component';
import { InputComponent } from './input/input.component';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { CustomNumberPipe } from './pipes/custom-number.pipe';
import { LocaleCurrencyPipe } from './pipes/locale-currency.pipe';
import { SelectComponent } from './select/select.component';
import { SinDatosComponent } from './sin-datos/sin-datos.component';
import { SubtitleComponent } from './subtitle/subtitle.component';
import { TablaDinamicaMovilComponent } from './tabla-dinamica-movil/tabla-dinamica-movil.component';
import { TablaDinamicaComponent } from './tabla-dinamica/tabla-dinamica.component';
import { TextComponent } from './text/text.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TimeComponent } from './time/time.component';

@NgModule({
  declarations: [
    OverlaySpinnerComponent,
    EnConstruccionComponent,
    CartasComponent,
    LocaleCurrencyPipe,
    DatosUsuarioComponent,
    DialogoRetroalimentacionComponent,
    BreadcrumbComponent,
    CustomDatePipe,
    CustomNumberPipe,
    BotonAyudaComponent,
    BotonBackComponent,
    SinDatosComponent,
    EncabezadoComponent,
    TablaDinamicaComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    CheckboxComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    SubtitleComponent,
    TextComponent,
    TextareaComponent,
    TablaDinamicaMovilComponent,
    TimeComponent,
    AutocompleteComponent,
    ButtonGroupComponent,
    DetallesComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    IgxTimePickerModule,
    IgxInputGroupModule,
    IgxIconModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      isolate: false
  })
  ],
  exports: [
    OverlaySpinnerComponent,
    CartasComponent,
    EnConstruccionComponent,
    LocaleCurrencyPipe,
    CustomDatePipe,
    CustomNumberPipe,
    DatosUsuarioComponent,
    DialogoRetroalimentacionComponent,
    HammerModule,
    IgxTimePickerModule,
    IgxInputGroupModule,
    IgxIconModule,
    BreadcrumbComponent,
    TranslateModule,
    BotonAyudaComponent,
    BotonBackComponent,
    SinDatosComponent,
    EncabezadoComponent,
    TablaDinamicaComponent,
    DynamicFormComponent,
    TablaDinamicaMovilComponent,
  ],
  entryComponents: [
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    CheckboxComponent,
    TextareaComponent,
    TextComponent,
    SubtitleComponent,
    TimeComponent
  ]
})
export class SharedModule {
 }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
