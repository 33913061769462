
<div class="back-div">
    <button mat-button color="moss"  (click)="RegresarARutaAnterior()">
        <mat-icon class="svg-white icono-regresar">
            keyboard_backspace
        </mat-icon>
        <span style="color: white;">Regresar</span>
    </button>
    <!-- <button mat-icon-button color="moss" style="margin-right: 12px;" (click)="RegresarARutaAnterior()">
        <mat-icon style="margin: 0 0.5rem; width: 16px; height: 16px;"
                  svgIcon="left-arrow"
                  class="svg-white">
        </mat-icon>
    </button> -->
</div>
