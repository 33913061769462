import { Injectable } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  private isLogged: boolean;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private _authService: AuthService
  ) {
    this._authService.estaAutenticado$.subscribe(value => this.isLogged = value);
  }

  /**
   * @returns Mensaje a mostrar en diálogo de ayuda en dependencia de la ruta.
   */
  public ObtenerMensajeDeAyuda() {
    const url = this.router.url;

    if (!this.isLogged) {
      return this.MensajeDeLogin();
    } else {
      if (url === '/consultas') {
        return this.MensajeDeConsultas();

      } else if (url === '/consultas/consulta_vacaciones') {
        return this.MensajeDeVacacionesConsultas();

      } else if (url === '/consultas/consulta_recibo') {
        return this.MensajeDeReciboConsultas();

      } else if (url === '/consultas/consulta_incapacidades') {
        return this.MensajeDeIncapacidadesConsultas();

      } else if (url === '/consultas/consulta_eventos') {
        return this.MensajeDeEventosConsultas();

      } else if (url === '/consultas/consulta_marcaciones') {
        return this.MensajeDeMarcasConsultas();

      } else if (url === '/solicitudes') {
        return this.MensajeDeSolicitudes();

      } else if (url === '/autorizaciones') {
        return this.MensajeDeAutorizaciones();

      } else if (url === '/solicitudes/crear-solicitud') {
        return this.MensajeDeCrearSolicitud();

      } else if (url === '/solicitudes/crear-solicitud/solicitud_constancia') {
        return this.MensajeDeCrearSolicitudConstancia();

      } else if (url === '/solicitudes/crear-solicitud/solicitud_permiso') {
        return this.MensajeDeCrearSolicitudPermiso();

      } else if (url === '/solicitudes/crear-solicitud/solicitud_amonestaciones') {
        return this.MensajeDeCrearSolicitudAmonestaciones();

      } else if (url === '/solicitudes/crear-solicitud/solicitud_descuentos_eventuales') {
        return this.MensajeDeCrearSolicitudDescuentosEventuales();

      } else if (url === '/solicitudes/crear-solicitud/solicitud_ingresos_eventuales') {
        return this.MensajeDeCrearSolicitudIngresosEventuales();

      } else if (url === '/solicitudes/crear-solicitud/solicitud_justificacion_de_marcas') {
        return this.MensajeDeCrearSolicitudJustificacionDeMarcas();

      } else if (url === '/solicitudes/crear-solicitud/solicitud_reconocimientos') {
        return this.MensajeDeCrearSolicitudReconocimientos();

      } else if (url === '/solicitudes/crear-solicitud/solicitud_registro_otros_patrones') {
        return this.MensajeDeCrearSolicitudRegistroOtrosPatrones();

      } else if (url === '/solicitudes/crear-solicitud/solicitud_reversion_de_contratacion') {
        return this.MensajeDeCrearSolicitudReversionContratacion();

      } else if (url === '/solicitudes/crear-solicitud/solicitud_tiempo_compensatorio') {
        return this.MensajeDeCrearSolicitudTiempoCompensatorio();

      } else if (url.includes('crear-solicitud') && (url.endsWith('/nuevo') || url.includes('/editar'))) {
        return this.MensajeDeCrearSolicitudFormulario();

      } else if (url === '/acciones') {
        return this.MensajeDeAcciones();

      } else if (url === '/acciones/acciones_autorizadores_sustitutos') {
        return this.MensajeDeAccionAutorizacionSustituto();

      } else if (url === '/perfil') {
        return this.MensajeDePerfil();

      } else {
        return this.MensajeDeOtros();
      }
    }
  }

  private MensajeDePerfil() {
    const key = 'help.main.perfil';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda de solicitud de vacaciones.
   */
  private MensajeDeCrearSolicitudVacaciones() {
    const key = 'help.solicitudes.vacaciones';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda para solicitud de permiso.
   */
  private MensajeDeCrearSolicitudPermiso() {
    const key = 'help.solicitudes.permisos';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda para solicitud de constancia.
   */
  private MensajeDeCrearSolicitudConstancia() {
    const key = 'help.solicitudes.constancias';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda para crear solicitud.
   */
  private MensajeDeCrearSolicitud() {
    const key = 'help.solicitudes.nueva_solicitud';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda para crear solicitud.
   */
  private MensajeDeCrearSolicitudAmonestaciones() {
    const key = 'help.solicitudes.amonestaciones';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda en pantalla de solicitud de descuentos eventuales.
   */
  private MensajeDeCrearSolicitudDescuentosEventuales() {
    const key = 'help.solicitudes.descuentosEventuales';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda en pantalla de solicitud de ingresos eventuales.
   */
  private MensajeDeCrearSolicitudIngresosEventuales() {
    const key = 'help.solicitudes.ingresosEventuales';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda en pantalla de solicitud de justificacion de marcas
   */
  private MensajeDeCrearSolicitudJustificacionDeMarcas() {
    const key = 'help.solicitudes.justificacionDeMarcas';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda en solicitud de reconocimientos
   */
  private MensajeDeCrearSolicitudReconocimientos() {
    const key = 'help.solicitudes.reconocimientos';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda en pantalla de registro de otros patronos
   */
  private MensajeDeCrearSolicitudRegistroOtrosPatrones() {
    const key = 'help.solicitudes.registroOtrosPatrones';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda en pantalla de reversion de contratacion
   */
  private MensajeDeCrearSolicitudReversionContratacion() {
    const key = 'help.solicitudes.reversionContratacion';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda en pantalla de tiempo compensatorio
   */
  private MensajeDeCrearSolicitudTiempoCompensatorio() {
    const key = 'help.solicitudes.tiempoCompensatorio';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda al momento de crear una nueva solicitud con el formulario dinámico
   */
  private MensajeDeCrearSolicitudFormulario() {
    const key = 'help.solicitudes.formulario';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda en el modulo de acciones
   */
  private MensajeDeAcciones() {
    const key = 'help.acciones.nueva_accion';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda al momento de crear un nuevo autorizador sustituto
   */
  private MensajeDeAccionAutorizacionSustituto() {
    const key = 'help.acciones.autorizadorSustituto';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda para autorizaciones.
   */
  private MensajeDeAutorizaciones() {
    const key = 'help.main.autorizaciones';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda para solicitudes.
   */
  private MensajeDeSolicitudes() {
    const key = 'help.main.solicitudes';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda para login.
   */
  private MensajeDeLogin() {
    const message = `Presiona el botón de 'INICIAR SESIÓN' para que puedas utilizar tu cuenta, y realizar consultas y/o solicitudes.`;
    return message;
  }

  /**
   * @returns Mensaje de ayuda para vista de consultas.
   */
  private MensajeDeConsultas() {
    const key = 'help.main.consultas';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda para la consulta de vacaciones.
   */
  private MensajeDeVacacionesConsultas() {
    const key = 'help.consultas.vacaciones';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda para la consulta de recibo de pago.
   */
  private MensajeDeReciboConsultas() {
    const key = 'help.consultas.recibo';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda para la consulta de incapacidades.
   */
  private MensajeDeIncapacidadesConsultas() {
    const key = 'help.consultas.incapacidades';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda para la consulta de eventos.
   */
  private MensajeDeEventosConsultas() {
    const key = 'help.consultas.eventos';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda para la consulta de marcas.
   */
  private MensajeDeMarcasConsultas() {
    const key = 'help.consultas.marcas';
    return this.translate.instant(key);
  }

  /**
   * @returns Mensaje de ayuda para otras pantallas.
   */
  private MensajeDeOtros() {
    const key = 'help.construccion';
    return this.translate.instant(key);
  }
}
