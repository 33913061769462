export class Utility {
  static loadJson<T>(file: string, defaultObject: T, onError = (event) => null ): T {
    const request = new XMLHttpRequest();
    request.open('GET', file, false);
    request.onerror = onError;
    if (request.overrideMimeType) {
      request.overrideMimeType('application/json');
    }
    request.send();
    if (request.status === 200) {
      return JSON.parse(request.responseText) as T;
    }
    return defaultObject;
  }
}
