import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

// TRADUCCION
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// MODULOS
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';
import { CuentaRoutingModule } from './cuenta-routing.module';

// COMPONENTES
import { TarjetaEmpleadoComponent } from './components/tarjeta-empleado/tarjeta-empleado.component';
import { TarjetaEmpleoComponent } from './components/tarjeta-empleo/tarjeta-empleo.component';
import { LoginComponent } from './login/login.component';
import { MiPerfilComponent } from './mi-perfil/mi-perfil.component';

@NgModule({
  declarations: [
    LoginComponent,
    MiPerfilComponent,
    TarjetaEmpleadoComponent,
    TarjetaEmpleoComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    CuentaRoutingModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      isolate: false
    })
  ],
  exports: [
    TranslateModule,
  ]
})
export class CuentaModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
