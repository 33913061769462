import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor() { }

  /**
   * @param url: Recibe URL para corregir último '/' de ruta.
   */
  public CheckAndCorrectURL(url: string): string {
    const lastLetter = url[url.length - 1];
    if (lastLetter === '/') {
      return url.slice(0, -1);
    } else {
      return url;
    }
  }
}
