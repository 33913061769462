import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { NotificacionesService } from '../services/notificaciones.service';
import { RutasService } from '../services/rutas.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private translate: TranslateService,
    private _authService: AuthService,
    private _rutasService: RutasService,
    private _notificacionesService: NotificacionesService,
    private logger: NGXLogger
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const nextPath: string = route.routeConfig.path;

    // Primero se valida si el usuario está autenticado
    if (this._authService.estaAutenticado()) {

      // Luego, validar si tiene permiso para navegar a esa ruta
      if (this._authService.estaAutorizado(nextPath)) {
        return true;
      }

      // De lo contrario, regresar al usuario al menú de consultas
      this.router.navigate(['/perfil'], { replaceUrl: true });
      this._notificacionesService.MostrarError(this.translate.instant('redireccion'), 10000);
      return false;
    }

    // El usuario no está autenticado, navegar al login
    this.router.navigate(
      ['/login'], { queryParams: { redirect: state.url }, replaceUrl: true }
    );
    return false;
  }

}
