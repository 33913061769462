import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../authentication/auth.service';
import { LogsService } from '../services/logs.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private injector: Injector
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const logs = this.injector.get(LogsService);
    logs.InfoLog(request);
    request = request.clone({
      setHeaders: {
        authorization : this._authService.authorizationHeaderValue
      }
    });
    return next.handle(request);
  }
}
