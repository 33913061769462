// GENERAL
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localEn from '@angular/common/locales/en';
import localEs from '@angular/common/locales/es-US';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppConfigService } from './core/services/app-config.service';

// MODULOS
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { CuentaModule } from './modules/cuenta/cuenta.module';
import { SharedModule } from './shared/shared.module';

// MISC
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './core/handlers/auth.interceptor';

// LIBRERÍAS ADICIONALES
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IgxBottomNavModule, IgxIconModule, IgxNavbarModule } from 'igniteui-angular';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { INGXLoggerConfig, LoggerModule, NGXLogger, NGXLoggerConfigEngine, NgxLoggerLevel } from 'ngx-logger';
import { MaterialModule } from './material.module';

// COMPONENTES
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AuthCallbackComponent } from './core/components/auth-callback/auth-callback.component';
import { HomeBottomNavbarComponent } from './core/components/bottom-navbar/bottom-navbar.component';
import { DialogoAyudaComponent } from './core/components/dialogo-ayuda/dialogo-ayuda.component';
import { DialogoLogoutComponent } from './core/components/dialogo-logout/dialogo-logout.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { SidenavComponent } from './core/components/sidenav/sidenav.component';

// MANEJO DE ERRORES
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalErrorHandler } from './core/handlers/global-error-handler';
import { ServerErrorInterceptor } from './core/handlers/server-error.interceptor';
import { XFrameInterceptor } from './core/handlers/x-frame.interceptor';

registerLocaleData(localEs, 'es');
registerLocaleData(localEn, 'en');
// Para mostrar fechas y datos en formato español

let nombreAplicacion;
let observable: Observable<boolean>;

const appInitializerFn = (appConfig: AppConfigService) => {
    return () => {
        return appConfig.loadAppConfig()
            .then(() => appConfig.loadValueListConfig())
            .then(() => {
                nombreAplicacion = '';
                observable = appConfig.configuracionCargada;

                if ('serviceWorker' in navigator && environment.production) {
                    const route = `./${nombreAplicacion}OneSignalSDKWorker.js`;
                    navigator.serviceWorker.register(route)
                    .then(() => {
                        console.log('registered!');
                    })
                    .catch((error) => {
                        console.log('Error al registrar el service worker:', error);
                    });
                }
            });
    };
};

@NgModule({
    declarations: [
        AppComponent,
        HomeBottomNavbarComponent,
        SidenavComponent,
        PageNotFoundComponent,
        AuthCallbackComponent,
        DialogoLogoutComponent,
        DialogoAyudaComponent,
    ],
    imports: [
        BrowserModule,
        CuentaModule,
        CoreModule,
        SharedModule,
        DeviceDetectorModule.forRoot(),
        BrowserAnimationsModule,
        IgxBottomNavModule,
        IgxIconModule,
        IgxNavbarModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        FlexLayoutModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            },
            isolate : false
          }),
        LoggerModule.forRoot({
            level: NgxLoggerLevel.INFO
        })
    ],
    exports: [
        AppComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ],
    providers: [
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppConfigService]
        },
        {provide: MAT_DATE_LOCALE, useValue: 'es-US'},
        {provide: LOCALE_ID, useValue: 'es-US'},
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: XFrameInterceptor, multi: true},
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }
