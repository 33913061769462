import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OneSignalService } from 'onesignal-ngx';
import { BehaviorSubject, Observable } from 'rxjs';
import { DispositivoUsuario } from '../interfaces/IDispositivoUsuario';
import { AppConfigService } from './app-config.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  private url: string;
  private nombreApp: string;
  private hasSubscribed: BehaviorSubject<boolean>;
  private isAvailable: BehaviorSubject<boolean>;
  private dispositivoId: string = null;
  private appId: string;

  private seHaInicializado = false;
  private evowaveUrl: string;

  constructor(
    private _environment: AppConfigService,
    private _urlService: UrlService,
    private deviceService: DeviceDetectorService,
    private http: HttpClient,
    private oneSignal: OneSignalService,
    private translate: TranslateService,
  ) {
    this.hasSubscribed = new BehaviorSubject<boolean>(false);
    this.isAvailable = new BehaviorSubject<boolean>(false);

    this._environment.datosDeConfiguracionCargados$.subscribe(
      (datosCargados) => {
        if (datosCargados) {
          const webapi = this._urlService.CheckAndCorrectURL(
            this._environment.config.WebApiUrl
          );
          this.evowaveUrl = this._urlService.CheckAndCorrectURL(
            this._environment.config.EvolutionWaveUrl
          );
          this.url = `${webapi}/api/Push`;
          this.nombreApp = this._environment.nombreApp ? `${this._environment.nombreApp}/` : '';
          this.appId = this._environment.config.OneSignalID;
          this.nombreApp = this._environment.nombreApp ? `${this._environment.nombreApp}/` : '';
        }
      }
    );
  }

  /**
   * Asigna en variable local si se está en el menú o no.
   * @param enMenu - Determina si está o no en el menú.
   */
  GuardarHasSubscribed(newHasSubscribed: boolean): void {
    this.hasSubscribed.next(newHasSubscribed);
  }

  ObtenerHasSubscribed(): Observable<boolean> {
    return this.hasSubscribed.asObservable();
  }

  GuardarIsAvailable(newIsAvailable: boolean): void {
    this.isAvailable.next(newIsAvailable);
  }

  ObtenerIsAvailable(): Observable<boolean> {
    return this.isAvailable.asObservable();
  }

  private TraducirTextosDeConfiguracion(): Promise<string[]> {
    return Promise.all([
      this.translate.get('perfil.notificaciones.bienvenido').toPromise(),
      this.translate.get('perfil.notificaciones.mensajeBienvenida').toPromise(),
      this.translate.get('perfil.slideDown.push.actionMessage').toPromise(),
      this.translate.get('perfil.slideDown.push.acceptButton').toPromise(),
      this.translate.get('perfil.slideDown.push.cancelButton').toPromise(),
    ]);
  }

  public async InitOneSignal() {
    const [
      tituloBienvenida,
      mensajeBienvenida,
      actionMessage,
      acceptButton,
      cancelButton
    ] = await this.TraducirTextosDeConfiguracion();

    if (!this.seHaInicializado) {
      const initConfig = {
        appId: this.appId,
        autoResubscribe: true,
        allowLocalhostAsSecureOrigin: true,
        welcomeNotification: {
          disable: false,
          title: tituloBienvenida,
          message: mensajeBienvenida
        },
        notifyButton: {
          enable: false,
        },
        promptOptions: {
          slidedown: {
            prompts: [{
              type: 'push',
              autoPrompt: true,
              text: {
                actionMessage,
                acceptButton,
                cancelButton
              },
              delay: {
                timeDelay: 20
              }
            }
          ]
          }
        },
        path: `/${this.nombreApp}`,
        serviceWorkerParam: { scope: `/${this.nombreApp}` }
      };

      await this.oneSignal.init(initConfig);

      this.RevisionInicial();
      this.seHaInicializado = true;
    }
  }

  private RevisionInicial() {
    this.oneSignal.isPushNotificationsEnabled((isEnabled) => {
      this.GuardarHasSubscribed(isEnabled);
    });

    this.oneSignal.getUserId(async (userId) => {
      this.GuardarIsAvailable(true);

      if (!userId) {
        return;
      }
      this.dispositivoId = userId;
      const infoDispositivo = await this.ObtenerInformacionDispositivo(userId);
      infoDispositivo.url_suscripcion = this.evowaveUrl;

      this.CrearSuscripcion(infoDispositivo);
      this.RevisarSuscripcion();
    });

    this.oneSignal.on('subscriptionChange', (isSubscribed) => {
      this.oneSignal.getUserId((userId) => {
        if (!userId) {
          return;
        }
        this.dispositivoId = userId;
        this.ObtenerInformacionDispositivo(userId).then((disp) => {
          disp.url_suscripcion = this.evowaveUrl;
          this.CrearSuscripcion(disp);
        });
      });

      this.oneSignal.isPushNotificationsEnabled((isEnabled) => this.GuardarHasSubscribed(isEnabled));
    });
  }

  public RevisarSuscripcion() {
    this.oneSignal.isPushNotificationsEnabled((isEnabled) => this.GuardarHasSubscribed(isEnabled));
  }

  public async IniciarSuscripcion() {
    if (this.dispositivoId) {
      this.UnmuteNotifications();
      return;
    }

    const permiso = await this.oneSignal.getNotificationPermission();
    if (permiso === 'default') {
      this.oneSignal.registerForPushNotifications();
    }
  }

  public async ObtenerInformacionDispositivo(
    playerId: string
  ): Promise<DispositivoUsuario> {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    const deviceUrl = `https://onesignal.com/api/v1/players/${playerId}?app_id=${this.appId}`;

    let result;

    await fetch(deviceUrl, requestOptions)
    .then(response => response.text())
    .then(text => result = JSON.parse(text) as DispositivoUsuario)
    .catch(error => console.log('error', error));

    return Promise.resolve(result ?? {});
  }

  public CrearSuscripcion(dispositivoWeb: DispositivoUsuario): Promise<any> {
    const urlCrear = `${this.url}/SubscribeWeb`;
    return this.http.post<any>(urlCrear, dispositivoWeb).toPromise();
  }

  public MuteNotifications() {
    this.oneSignal.setSubscription(false);
  }

  public UnmuteNotifications() {
    this.oneSignal.setSubscription(true);
  }
}
