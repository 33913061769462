<mat-card>
    <mat-card-content fxLayout="row">
        <!-- ICONO/ESTRELLA -->
        <div class="tarjeta-icon-space">
            <mat-icon svgIcon="{{icono}}"></mat-icon>
        </div>
        <!-- DATOS DEL EMPLEO -->
        <div class="tarjeta-empleo-space">
            <div class="tarjeta-datos-empleo" fxLayout="column" fxLayoutAlign="space-around start" >
                <p class="tarjeta-texto-empleo">{{nombrePuesto}}</p>
                <p>{{nombreUnidad}}</p>
                <p>{{nombreCompania}}</p>
                <p class="pos_fixed">{{nombreCentroTrabajo}}</p>
            </div>
        </div>
        <!-- BOTON -->
        <div class="tarjeta-button-space">
            <button
                mat-flat-button
                color="primary"
                [disabled]="seleccionado"
                (click)="CambioDeEmpleo()">
                {{seleccionado ? ('perfil.seleccionado' | translate) : ('perfil.seleccionar' | translate) }}
            </button>
        </div>
    </mat-card-content>
</mat-card>