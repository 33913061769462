import {
  HttpErrorResponse, HttpEvent, HttpHandler,
  HttpInterceptor, HttpRequest, HttpResponseBase
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import StackTrace from 'stacktrace-js';
import { LogsService } from '../services/logs.service';
import { NotificacionesService } from '../services/notificaciones.service';

@Injectable({
  providedIn: 'root'
})
export class ServerErrorInterceptor implements HttpInterceptor {

  constructor(
    private _notificacionesService: NotificacionesService,
    private logger: LogsService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((response: HttpErrorResponse) => {

        // En el caso que el código sea 200 - 299
        if (this.is2xxStatus(response)) {
          return new Observable<HttpEvent<any>>();
        }

        const { error, statusText } = response;
        let descripcionError = '';
        const errorToast = 'Ha ocurrido un error. Por favor, intente de nuevo más tarde.';

        // El error es es un evento de Angular?
        // Entonces es problema del cliente
        if (error instanceof ErrorEvent) {
          descripcionError += '(cliente) ';
        }

        // Si la respuesta viene del api
        if (error.hasOwnProperty('ExceptionMessage')) {
          descripcionError += error.ExceptionMessage;
        }

        if (error.hasOwnProperty('Message')) {
          descripcionError += error.Message;
        }

        this.logger.ErrorLog(error);
        StackTrace.fromError(error).then((stackFrame) => this.logger.StackLog(stackFrame));
        // this._notificacionesService.MostrarError(errorToast);
        return throwError(descripcionError);
      })
    );
  }

  private is2xxStatus(response: HttpResponseBase) {
    return response.status >= 200 && response.status < 300;
  }
}
