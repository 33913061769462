<div style="display: flex; align-items: center;">
    <span>{{'help.boton' | translate}}</span>
    <button mat-icon-button #botonAyuda (click)="AbrirDialogoAyuda(); NoEnfocarBoton(botonAyuda)">
        <mat-icon style="margin: 0 0.5rem;"
                  svgIcon="question"
                  class="svg-moss icon-size-info2"
                  style="filter: brightness(0) saturate(100%);
                  filter: invert(51%) sepia(35%) saturate(418%) hue-rotate(61deg) brightness(100%) contrast(88%);">
        </mat-icon>
    </button>
</div>