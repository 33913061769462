import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogoLogoutComponent } from '../dialogo-logout/dialogo-logout.component';

@Component({
  selector: 'app-dialogo-ayuda',
  templateUrl: './dialogo-ayuda.component.html',
  styleUrls: ['./dialogo-ayuda.component.scss']
})
export class DialogoAyudaComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogoLogoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  /**
   * Maneja el click en el botón "entendido"
   */
  ClickEntendido(): void {
    this.dialogRef.close();
  }
}
