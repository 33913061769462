import { Injectable } from '@angular/core';
import { INGXLoggerConfig, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { EmpleadoService } from 'src/app/core/services/empleado.service';
import { Utility } from '../classes/utility';
import { AppConfigService } from '../services/app-config.service';
import { RutasService } from '../services/rutas.service';
import { UrlService } from '../services/url.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private managerDeUsuario: UserManager;
  private config;
  private usuario: User | null;

  // Está autenticado source observable
  private estaAutenticadoSubject$ = new BehaviorSubject<boolean>(false);
  // Stream observable
  public estaAutenticado$ = this.estaAutenticadoSubject$.asObservable();

  constructor(
    private _empleadoService: EmpleadoService,
    private _environment: AppConfigService,
    private _urlService: UrlService,
    private _rutasService: RutasService,
    private logger: NGXLogger
  ) {
    this.ObtenerConfiguracionDesdeJson();
  }

  private configurarLogger() {
    this.logger.updateConfig(Utility.loadJson<INGXLoggerConfig>(
      `${this.config.EvolutionWaveUrl}/assets/logger-config.json`,
      {
        level: NgxLoggerLevel.OFF,
      }
    ));
  }

  /**
   * Redirige al usuario a la página de Login del IDS
   */
  Login() {
    return this.managerDeUsuario.signinRedirect();
  }

  /**
   * @async Función asíncrona que termina el proceso de autenticación, una vez el usuario
   * ha sido redirigido del login. Además, guardar los datos del usuario que devuelve IDS.
   * NOTA: el IDS regresa pocos datos significativos del usuario.
   */
  async CompletarProcesoDeAutenticacion() {
    this.usuario = await this.managerDeUsuario.signinRedirectCallback();

    const culture = this.usuario.profile.culture;

    const rutas = await this._rutasService.ObtenerPermisosDeRutas(culture);
    this._rutasService.GuardarPermisosDeRutas(rutas);

    // Definir el empleado con uso del service
    this._empleadoService.SetDatosDeIdentityService(
      this.usuario.profile.codigo_expediente,
      this.usuario.profile.preferred_username,
      this.usuario.profile.given_name,
      this.usuario.profile.culture,
      this.usuario.profile.uiculture
    );
    // Cambiar el estado de la variable está autenticado
    this.estaAutenticadoSubject$.next(this.estaAutenticado());
  }

  /**
   * Realiza un signin de manera 'silenciosa' para renovar la sesión del usuario
   *
   * @returns Una promesa de tipo User propia de IDS
   */
  silentRefresh(): Promise<User> {
    return this.managerDeUsuario.signinSilent();
  }

  /**
   * Obtiene los datos del empleado desde el API, al mismo tiempo que actualiza al usuario
   * para guardar los datos más completos.
   *
   * @returns Una promesa tipo void si los datos del usuario fueron obtenidos y guardados
   */
  public async ObtenerYGuardarDatosDelEmpleado(): Promise<void> {
    const empleado = await this._empleadoService
      .ConsultarDatosDelEmpleadoDesdeApi();
    return this._empleadoService.SetDatosDelEmpleado(
      empleado,
      this.usuario.profile
    );
  }

  /**
   * Ejecuta el método `next` en el Subject booleano para la autenticación de un usuario
   * @param value El valor a setear luego de algún procedimiento
   */
  public SetUsuarioEstaAutenticado(value: boolean) {
    this.estaAutenticadoSubject$.next(value);
  }

  /**
   * Valor booleano para saber si la sesión dle usuario con IDS es válida y no ha expirado.
   * @returns Valor boolean si el usuario está o no autenticado.
   */
  public estaAutenticado(): boolean {
    if (this.usuario !== null && this.usuario !== undefined) {
      return !this.usuario.expired;
    }
    return false;
  }

  /**
   * @returns El token del IDS asociado al usuario
   */
  get authorizationHeaderValue(): string {
    return this.usuario ? `${this.usuario.token_type} ${this.usuario.access_token}` : 'NA';
  }

  /**
   * @returns El nombre del usuario de IDS
   */
  get name(): string {
    return this.usuario !== null ? this.usuario.profile.name : '';
  }

  /**
   * @returns Objeto tipo User de IDS
   */
  ObtenerObjetoUsuario(): User {
    return this.usuario;
  }

  estaAutorizado(nextPath): Observable<boolean> {
    return new Observable((observer) => {
      const permisos = localStorage.getItem('permisos');
      if (!permisos) {
        this._rutasService.Permisos(this.usuario.profile.culture).subscribe((permisosRes) => {
          this._rutasService.GuardarPermisosDeRutas(permisosRes);
          localStorage.setItem('permisos', JSON.stringify(permisosRes));
          observer.next(this._rutasService.PuedeNavegar(nextPath));
          observer.complete();
        });
      } else {
        observer.next(this._rutasService.PuedeNavegar(nextPath));
        observer.complete();
      }
    });
  }

  /**
   * Guarda el usuario, obtiene las rutas permitidas y agrega evento cuando el
   * token está a punto de expirar.
   * @param usuario: User manejado por oidc-client
   */
  async GuardarUsuarioIDS(usuario: User): Promise<void> {
    // Guardamos el usuario de IDS
    this.usuario = usuario;

    if (!this.estaAutenticado()) {
      this.estaAutenticadoSubject$.next(false);
      return;
    }

    // Obtenemos las rutas a las que el usuario puede acceder
    const rutas = await this._rutasService.ObtenerPermisosDeRutas(usuario.profile.culture);
    this._rutasService.GuardarPermisosDeRutas(rutas);

    // Seteamos el valor que el usuario está autenticado
    this.estaAutenticadoSubject$.next(this.estaAutenticado());

    // Agregar evento cuando el token está a punto de expirar
    this.managerDeUsuario.events.addAccessTokenExpiring(() => {
      this.silentRefresh()
      .then((usuarioRenovado: User) => this.usuario = usuarioRenovado);
    });
  }

  /**
   * Obtiene las configuraciones desde el app-config.json y luego consulta el
   * usuario a la clase UserManager de IDS.
   */
  ObtenerConfiguracionDesdeJson(): void {
    let suscripcionConfiguracionDeJsonCargada: Subscription;
    const self = this;

    suscripcionConfiguracionDeJsonCargada = this._environment.datosDeConfiguracionCargados$.subscribe(
      (datosCargados) => {
        if (datosCargados) {
          this.config = this._environment.config;
          const ids = self._urlService.CheckAndCorrectURL(
            this.config.IdentityServerUrl
          );
          const wave = self._urlService.CheckAndCorrectURL(
            this.config.EvolutionWaveUrl
          );
          this.managerDeUsuario = new UserManager(
            ObtenerConfiguracionDelCliente(ids, wave)
          );
          // Obtenemos el usuario del user Manager y lo guardamos localmente
          this.managerDeUsuario.getUser().then(async (usuario: User) => {
            return usuario ? this.GuardarUsuarioIDS(usuario) : '';
          });
          this.configurarLogger();
        }
      }
    );
  }

  /**
   * Cierra la sesión de IDS
   *
   * @async redirige al IDS luego de cerrar la sesión del usuario.
   */
  async CerrarSesion() {
    localStorage.removeItem('permisos');
    await this.managerDeUsuario.signoutRedirect();
  }

}

export function ObtenerConfiguracionDelCliente(idsUrl: string, evoWaveUrl: string): UserManagerSettings {
  return {
    authority: `${idsUrl}/`,
    client_id: 'EvolutionPWA',
    redirect_uri: `${evoWaveUrl}/auth-callback`,
    response_type: 'id_token token',
    scope:
      'openid profile codigo_expediente Aseinfo_VH4_WebApi culture uiculture',
    post_logout_redirect_uri: `${evoWaveUrl}/login`,
    loadUserInfo: false,
    filterProtocolClaims: true,
    automaticSilentRenew: false,
    silent_redirect_uri: `${evoWaveUrl}/assets/silent-refresh.html`,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };
}
